export const SET_PAGE_CONF = 'SET_PAGE_CONF';
export const ENABLE_ALERT_ERROR = 'ENABLE_ALERT_ERROR';
export const DISABLE_ALERT_ERROR = 'DISABLE_ALERT_ERROR';
export const ENABLE_ALERT_SUCCESS = 'ENABLE_ALERT_SUCCESS';
export const DISABLE_ALERT_SUCCESS = 'DISABLE_ALERT_SUCCESS';

export const GET_APP_INFO_REQUEST = 'GET_APP_INFO_REQUEST';
export const GET_APP_INFO_FAILURE = 'GET_APP_INFO_FAILURE';
export const GET_APP_INFO_SUCCESS = 'GET_APP_INFO_SUCCESS';

export const GET_PAGE_DATA_REQUEST = 'GET_PAGE_DATA_REQUEST';
export const GET_PAGE_DATA_FAILURE = 'GET_PAGE_DATA_FAILURE';
export const GET_PAGE_DATA_SUCCESS = 'GET_PAGE_DATA_SUCCESS';

export const LOGIN_EMAIL_REQUEST = 'LOGIN_EMAIL_REQUEST';
export const LOGIN_EMAIL_FAILURE = 'LOGIN_EMAIL_FAILURE';
export const LOGIN_EMAIL_SUCCESS = 'LOGIN_EMAIL_SUCCESS';

export const LOGOUT_EMAIL_REQUEST = 'LOGOUT_EMAIL_REQUEST';
export const LOGOUT_EMAIL_FAILURE = 'LOGOUT_EMAIL_FAILURE';
export const LOGOUT_EMAIL_SUCCESS = 'LOGOUT_EMAIL_SUCCESS';

export const RECOVER_PASSWORD_REQUEST = 'RECOVER_PASSWORD_REQUEST';
export const RECOVER_PASSWORD_FAILURE = 'RECOVER_PASSWORD_FAILURE';
export const RECOVER_PASSWORD_SUCCESS = 'RECOVER_PASSWORD_SUCCESS';

export const REGISTER_EMAIL_REQUEST = 'REGISTER_EMAIL_REQUEST';
export const REGISTER_EMAIL_FAILURE = 'REGISTER_EMAIL_FAILURE';
export const REGISTER_EMAIL_SUCCESS = 'REGISTER_EMAIL_SUCCESS';

export const GET_TERMINAL_DATA_REQUEST = 'GET_TERMINAL_DATA_REQUEST';
export const GET_TERMINAL_DATA_FAILURE = 'GET_TERMINAL_DATA_FAILURE';
export const GET_TERMINAL_DATA_SUCCESS = 'GET_TERMINAL_DATA_SUCCESS';

export const GET_TERMINAL_CHART_LIST_REQUEST = 'GET_TERMINAL_CHART_LIST_REQUEST';
export const GET_TERMINAL_CHART_LIST_FAILURE = 'GET_TERMINAL_CHART_LIST_FAILURE';
export const GET_TERMINAL_CHART_LIST_SUCCESS = 'GET_TERMINAL_CHART_LIST_SUCCESS';

export const GET_TERMINAL_CHART_HISTORY_REQUEST = 'GET_TERMINAL_CHART_HISTORY_REQUEST';
export const GET_TERMINAL_CHART_HISTORY_FAILURE = 'GET_TERMINAL_CHART_HISTORY_FAILURE';
export const GET_TERMINAL_CHART_HISTORY_SUCCESS = 'GET_TERMINAL_CHART_HISTORY_SUCCESS';

export const GET_PROFILE_INFO_REQUEST = 'GET_PROFILE_INFO_REQUEST';
export const GET_PROFILE_INFO_FAILURE = 'GET_PROFILE_INFO_FAILURE';
export const GET_PROFILE_INFO_SUCCESS = 'GET_PROFILE_INFO_SUCCESS';

export const GET_URL_INCREASE_REQUEST = 'GET_URL_INCREASE_REQUEST';
export const GET_URL_INCREASE_FAILURE = 'GET_URL_INCREASE_FAILURE';
export const GET_URL_INCREASE_SUCCESS = 'GET_URL_INCREASE_SUCCESS';

export const SEND_OUTPUT_REQUEST = 'SEND_OUTPUT_REQUEST';
export const SEND_OUTPUT_FAILURE = 'SEND_OUTPUT_FAILURE';
export const SEND_OUTPUT_SUCCESS = 'SEND_OUTPUT_SUCCESS';

export const GET_BALANCE_REQUEST = 'GET_BALANCE_REQUEST';
export const GET_BALANCE_FAILURE = 'GET_BALANCE_FAILURE';
export const GET_BALANCE_SUCCESS = 'GET_BALANCE_SUCCESS';

export const SET_DEAL_REQUEST = 'SET_DEAL_REQUEST';
export const SET_DEAL_FAILURE = 'SET_DEAL_FAILURE';
export const SET_DEAL_SUCCESS = 'SET_DEAL_SUCCESS';

export const GET_DEALS_LIST_REQUEST = 'GET_DEALS_LIST_REQUEST';
export const GET_DEALS_LIST_FAILURE = 'GET_DEALS_LIST_FAILURE';
export const GET_DEALS_LIST_SUCCESS = 'GET_DEALS_LIST_SUCCESS';

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';

export const CHANGE_PROFILE_INFO_REQUEST = 'CHANGE_PROFILE_INFO_REQUEST';
export const CHANGE_PROFILE_INFO_FAILURE = 'CHANGE_PROFILE_INFO_FAILURE';
export const CHANGE_PROFILE_INFO_SUCCESS = 'CHANGE_PROFILE_INFO_SUCCESS';

export const RESTORE_VIRTUAL_BALANCE_REQUEST = 'RESTORE_VIRTUAL_BALANCE_REQUEST';
export const RESTORE_VIRTUAL_BALANCE_FAILURE = 'RESTORE_VIRTUAL_BALANCE_FAILURE';
export const RESTORE_VIRTUAL_BALANCE_SUCCESS = 'RESTORE_VIRTUAL_BALANCE_SUCCESS';

export const CHECK_PROMOCODE_REQUEST = 'CHECK_PROMOCODE_REQUEST';
export const CHECK_PROMOCODE_FAILURE = 'CHECK_PROMOCODE_FAILURE';
export const CHECK_PROMOCODE_SUCCESS = 'CHECK_PROMOCODE_SUCCESS';

export const GET_HISTORY_OUTPUT_REQUEST = 'GET_HISTORY_OUTPUT_REQUEST';
export const GET_HISTORY_OUTPUT_FAILURE = 'GET_HISTORY_OUTPUT_FAILURE';
export const GET_HISTORY_OUTPUT_SUCCESS = 'GET_HISTORY_OUTPUT_SUCCESS';

export const GET_HISTORY_INCREASES_REQUEST = 'GET_HISTORY_INCREASES_REQUEST';
export const GET_HISTORY_INCREASES_FAILURE = 'GET_HISTORY_INCREASES_FAILURE';
export const GET_HISTORY_INCREASES_SUCCESS = 'GET_HISTORY_INCREASES_SUCCESS';

export const GET_HISTORY_PROMOCODES_REQUEST = 'GET_HISTORY_PROMOCODES_REQUEST';
export const GET_HISTORY_PROMOCODES_FAILURE = 'GET_HISTORY_PROMOCODES_FAILURE';
export const GET_HISTORY_PROMOCODES_SUCCESS = 'GET_HISTORY_PROMOCODES_SUCCESS';


export const CHANGE_AFFILIATE_PASSWORD_REQUEST = 'CHANGE_AFFILIATE_PASSWORD_REQUEST';
export const CHANGE_AFFILIATE_PASSWORD_FAILURE = 'CHANGE_AFFILIATE_PASSWORD_FAILURE';
export const CHANGE_AFFILIATE_PASSWORD_SUCCESS = 'CHANGE_AFFILIATE_PASSWORD_SUCCESS';

export const GET_AFFILIATE_PROFILE_INFO_REQUEST = 'GET_AFFILIATE_PROFILE_INFO_REQUEST';
export const GET_AFFILIATE_PROFILE_INFO_FAILURE = 'GET_AFFILIATE_PROFILE_INFO_FAILURE';
export const GET_AFFILIATE_PROFILE_INFO_SUCCESS = 'GET_AFFILIATE_PROFILE_INFO_SUCCESS';

export const CHANGE_AFFILIATE_WALLET_INFO_REQUEST = 'CHANGE_AFFILIATE_WALLET_INFO_REQUEST';
export const CHANGE_AFFILIATE_WALLET_INFO_FAILURE = 'CHANGE_AFFILIATE_WALLET_INFO_FAILURE';
export const CHANGE_AFFILIATE_WALLET_INFO_SUCCESS = 'CHANGE_AFFILIATE_WALLET_INFO_SUCCESS';

export const CHANGE_AFFILIATE_PROFILE_INFO_REQUEST = 'CHANGE_AFFILIATE_PROFILE_INFO_REQUEST';
export const CHANGE_AFFILIATE_PROFILE_INFO_FAILURE = 'CHANGE_AFFILIATE_PROFILE_INFO_FAILURE';
export const CHANGE_AFFILIATE_PROFILE_INFO_SUCCESS = 'CHANGE_AFFILIATE_PROFILE_INFO_SUCCESS';

export const SEND_AFFILIATE_OUTPUT_REQUEST = 'SEND_AFFILIATE_OUTPUT_REQUEST';
export const SEND_AFFILIATE_OUTPUT_FAILURE = 'SEND_AFFILIATE_OUTPUT_FAILURE';
export const SEND_AFFILIATE_OUTPUT_SUCCESS = 'SEND_AFFILIATE_OUTPUT_SUCCESS';

export const GET_AFFILIATE_BALANCE_REQUEST = 'GET_AFFILIATE_BALANCE_REQUEST';
export const GET_AFFILIATE_BALANCE_FAILURE = 'GET_AFFILIATE_BALANCE_FAILURE';
export const GET_AFFILIATE_BALANCE_SUCCESS = 'GET_AFFILIATE_BALANCE_SUCCESS';

export const GET_AFFILIATE_HISTORY_OUTPUT_REQUEST = 'GET_AFFILIATE_HISTORY_OUTPUT_REQUEST';
export const GET_AFFILIATE_HISTORY_OUTPUT_FAILURE = 'GET_AFFILIATE_HISTORY_OUTPUT_FAILURE';
export const GET_AFFILIATE_HISTORY_OUTPUT_SUCCESS = 'GET_AFFILIATE_HISTORY_OUTPUT_SUCCESS';

export const GET_AFFILIATE_INFO_REQUEST = 'GET_AFFILIATE_INFO_REQUEST';
export const GET_AFFILIATE_INFO_FAILURE = 'GET_AFFILIATE_INFO_FAILURE';
export const GET_AFFILIATE_INFO_SUCCESS = 'GET_AFFILIATE_INFO_SUCCESS';

export const CREATE_AFFILIATE_INVITATION_LINK_REQUEST = 'CREATE_AFFILIATE_INVITATION_LINK_REQUEST';
export const CREATE_AFFILIATE_INVITATION_LINK_FAILURE = 'CREATE_AFFILIATE_INVITATION_LINK_FAILURE';
export const CREATE_AFFILIATE_INVITATION_LINK_SUCCESS = 'CREATE_AFFILIATE_INVITATION_LINK_SUCCESS';

export const GET_AFFILIATE_HISTORY_REQUEST = 'GET_AFFILIATE_HISTORY_REQUEST';
export const GET_AFFILIATE_HISTORY_FAILURE = 'GET_AFFILIATE_HISTORY_FAILURE';
export const GET_AFFILIATE_HISTORY_SUCCESS = 'GET_AFFILIATE_HISTORY_SUCCESS';

export const GET_INVESTMENTS_REQUEST = 'GET_INVESTMENTS_REQUEST';
export const GET_INVESTMENTS_FAILURE = 'GET_INVESTMENTS_FAILURE';
export const GET_INVESTMENTS_SUCCESS = 'GET_INVESTMENTS_SUCCESS';

export const GET_SEARCH_CHART_LIST_REQUEST = 'GET_SEARCH_CHART_LIST_REQUEST';
export const GET_SEARCH_CHART_LIST_FAILURE = 'GET_SEARCH_CHART_LIST_FAILURE';
export const GET_SEARCH_CHART_LIST_SUCCESS = 'GET_SEARCH_CHART_LIST_SUCCESS';

export const GET_USER_INFO_REQUEST = 'GET_USER_INFO_REQUEST';
export const GET_USER_INFO_FAILURE = 'GET_USER_INFO_FAILURE';
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST';
export const USER_DELETE_FAILURE = 'USER_DELETE_FAILURE';
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS';

export const GET_INCREASE_INFO_REQUEST = 'GET_INCREASE_INFO_REQUEST';
export const GET_INCREASE_INFO_FAILURE = 'GET_INCREASE_INFO_FAILURE';
export const GET_INCREASE_INFO_SUCCESS = 'GET_INCREASE_INFO_SUCCESS';

export const GET_PAYMENT_TYPES_REQUEST = 'GET_PAYMENT_TYPES_REQUEST';
export const GET_PAYMENT_TYPES_FAILURE = 'GET_PAYMENT_TYPES_FAILURE';
export const GET_PAYMENT_TYPES_SUCCESS = 'GET_PAYMENT_TYPES_SUCCESS';

export const CONFIRM_REQUEST = 'CONFIRM_REQUEST';
export const CONFIRM_FAILURE = 'CONFIRM_FAILURE';
export const CONFIRM_SUCCESS = 'CONFIRM_SUCCESS';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';

export const PROFILE_CONFIRM_DELETE_REQUEST = 'PROFILE_CONFIRM_DELETE_REQUEST';
export const PROFILE_CONFIRM_DELETE_FAILURE = 'PROFILE_CONFIRM_DELETE_FAILURE';
export const PROFILE_CONFIRM_DELETE_SUCCESS = 'PROFILE_CONFIRM_DELETE_SUCCESS';

export const SEND_FILE_VERIFY_REQUEST = 'SEND_FILE_VERIFY_REQUEST';
export const SEND_FILE_VERIFY_FAILURE = 'SEND_FILE_VERIFY_FAILURE';
export const SEND_FILE_VERIFY_SUCCESS = 'SEND_FILE_VERIFY_SUCCESS';
