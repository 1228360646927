import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {
    output,
    getBalance
} from '../../../../actions/payment'

import './index.scss'

class PaymentOutput extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            cardNumber: '',
            cardHolder: '-',
            orderAmount: '',
            isOpenInfo: null,
            topOpenBlock: 0,
            selected: "MasterCard"
        };

        this.info = [
            {
                title: "Как вывести деньги со счета?",
                text: `<p>
                            Преимущество торговой платформы Компании в том числе в том, что Вам не придется зачислять на счет крупные суммы. Начать торговлю Вы можете, инвестировав незначительную сумму денег.  Минимальный депозит составляет 50 долларов США.
                        </p>
                `
            },
            {
                title: "Нужно ли мне проходить верефикацию?",
                text: `<p>
							Процедура вывода капитала предельно проста и совершается
							через Ваш личный кабинет.
						</p>
						<p>
							Выберите платежный метод, введите доступную сумму для вывода
							и создайте заявку.
						</p>
						<p>
							Когда речь идет о выводе достаточно крупной суммы, Компания
							может запросить верификацию (верификация запрашивается на
							личное усмотрение Компании), вот почему очень важно
							регистрировать счет лично на себя, чтобы в любой момент
							время подтвердить свои права на него.
						</p>
						<p>
							Если сумма Вашего вывода меньше 10.000$, тогда верефикацию
							проходить не требуется.
						</p>`
            },
            {
                title: "Сколько времени занимает вывод средств?",
                text: `<p>
                            В среднем процедура вывода средств занимает от одного до
                            пяти дней с даты получения соответствующей заявки Клиента и
                            зависит только от объема одновременно обрабатываемых заявок.
                            Компания всегда старается производить выплаты
                            непосредственно в день поступления запроса от Клиента.
                        </p>
                `
            },
            {
                title: "Существует ли комиссия при выводе средств?",
                text: `<p>
                            Нет. Компания не взымает комиссию ни за операцию пополнения
                            счета, ни за вывод средств.
                        </p>
                        <p>
                            Однако, стоит учитывать, что платежные системы могут взимать
                            свои комиссии и использовать внутренний курс конвертации
                            валют.
                        </p>`
            }
        ]
    }

    componentDidMount() {
        this.props.getBalance();
    }

    sendOutput() {
        let {
            cardNumber,
            cardHolder,
            orderAmount,
            selected,
            balance
        } = this.state;

        let {
            min_increase_amount
        } = this.props.payment;

        if(cardNumber.length === 0 
            || cardHolder.length === 0 
            || +orderAmount > balance 
            ||  +orderAmount < min_increase_amount) return false

        this.props.output({
            order_type: selected,
            card_number: cardNumber,
            card_holder: cardHolder,
            order_amount: orderAmount
        });

        this.setState({
            cardNumber: '',
            cardHolder: '',
            orderAmount: ''
        });

    }

    render() {
        if (!this.props.payment) return false;

        let {
            min_increase_amount,
            balance
        } = this.props.payment;

        let {
            cardNumber,
            orderAmount,
            isOpenInfo,
            topOpenBlock,
            selected
        } = this.state;

        this.list = ["Bitcoin", "PayPal", "Perfect Money", "MasterCard", "AdvCash", "Visa", "Payeer", "Мир", "Qiwi", "Яндекс Деньги"]

        return (
            <div className="payment_output">
                <div className="withdraw__row">
                    <div className="content__mid-left">
                        <div className="content__mid-col withdraw__col-left">
                            <div className="data_block">
                                <div className="data_block-title">Платежная система:</div>
                                <div className="data_block-cards">
                                    {
                                        this.list.map((item, key) => {
                                            return(
                                                <button key={key} onClick={()=> this.setState({ selected: item }) } className={selected === item ? "card_item selected" : "card_item"}>
                                                    <div className="card_item__icon">
                                                        <img src={`/images/icons/payment/${item.toLowerCase()}.png`} alt={item}/>
                                                    </div>
                                                    <div className="card_item__text">{item}</div>
                                                </button>
                                            )
                                        })
                                    }
                                </div>
                                <div className="withdraw__label">Выберите платежную систему для вывода
                                    средств
                                </div>
                            </div>
                        </div>
                        <div className="content__mid-col withdraw__col-right">
                            <div className="data_block">
                                <div className="data_block-title">Аккаунт:</div>
                                <div className="data_block-accInfo">
                                    <div className="accInfo__item account_info">
                                        <div className="accInfo__item__title">На счету:</div>
                                        <div className="accInfo__item__value">{parseFloat(balance).toFixed(2)} $</div>
                                    </div>
                                    <div className="accInfo__item available_info">
                                        <div className="accInfo__item__title">Доступно для вывода:</div>
                                        <div className="accInfo__item__value">{parseFloat(balance).toFixed(2)} $</div>
                                    </div>
                                    <div className="accInfo__item bonus_info">
                                        <div className="accInfo__item__title">Бонусы:</div>
                                        <div className="accInfo__item__value">0.00 $</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content__mid-right">
                        <div className="content__mid-col">
                            <div className="data_block">
                                <div className="data_block-title">Выбранный метод вывода средств:</div>
                                <div className="data_block-payment_type">
                                    <div className="payment_type__header">
                                        <div className="row header_row">
                                            <img src={`/images/icons/payment/${selected.toLowerCase()}.png`} alt={selected} />
                                            <div className="header__title">{selected}</div>
                                        </div>
                                    </div>
                                    <div className="payment_type__body">
                                        <div className="row amount_row">
                                            <div className="amount_row__prop">Мин. Сумма:</div>
                                            <div className="amount_row__value">{parseFloat(min_increase_amount).toFixed(2)} $</div>
                                        </div>
                                        <div className="row amount_row">
                                            <div className="amount_row__prop">Макс. Сумма:</div>
                                            <div className="amount_row__value">10.000$<span> за одну операцию </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="data_block">
                                <div className="data_block-inputs">
                                    <div className="input_cover">
                                        <div className="input_label">Сумма</div>
                                        <input  type="number"
                                                min={min_increase_amount}
                                                step="1"
                                                name="amount" 
                                                value={orderAmount}
                                                onChange={e => this.setState({orderAmount: e.target.value})}
                                        />
                                        <div className="input_placeholder">USD</div>
                                    </div>
                                    {/*<div className="input_cover">
                                        <div className="input_label">Держатель карты</div>
                                        <input  name="cardOwner"      
                                                type="text"
                                                defaultValue={cardHolder}
                                                onChange={e => this.setState({cardHolder: e.target.value})}
                                        />
                                    </div>*/}
                                    <div className="input_cover">
                                        <div className="input_label">Номер счета</div>
                                        <input  name="cardNumber"   
                                                type="text"
                                                value={cardNumber}
                                                onChange={e => this.setState({cardNumber: e.target.value})}
                                        />
                                    </div>
                                </div>
                                {
                                    parseInt(this.props.withdraw) === 0 ? <button className="btn btn_blue withdraw_btn" onClick={() => this.sendOutput()}>Вывести →</button> :
                                        <div className="data_block-content">
                                            <p>Вашему аккаунту необходимо иметь минимальный депозит в $300 для вывода средств.</p>
                                        </div>
                                }
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content__mid-bottom">
                    <div className="content__mid-col withdraw__questions">
                        <ul className="withdraw__questions-tabs">
                            {
                                this.info.map((item, key) => {
                                    return (
                                        <li key={key} onClick={(e) => {
                                            const element = e.target.getBoundingClientRect();
                                            this.setState({isOpenInfo: key, topOpenBlock: element.top - 50})
                                        }}>{item.title}</li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                    {
                        this.info[isOpenInfo] === undefined ? null :
                            <div className="content__mid-col withdraw__answers"
                                 style={{'position': 'absolute', 'top': `${topOpenBlock}px`, 'left': "480px"}}>
                                <div className="tab_container">
                                    <div className="withdraw__tab_content" id="tab3_refill">
                                        <div className="withdraw__answers-item">
                                            <div className="item__header">
                                                <div className="header__left">
                                                    <div className="item__header-icon"><img src="/images/icons/arrow_left.svg"
                                                                                            alt="arrow_left.svg"/></div>
                                                    <div
                                                        className="item__header-title">{this.info[isOpenInfo].title}</div>
                                                </div>

                                                <div className="header__right tab_close"
                                                     onClick={() => this.setState({isOpenInfo: null})}>X
                                                </div>
                                            </div>
                                            <div className="item__body"
                                                 dangerouslySetInnerHTML={{__html: this.info[isOpenInfo].text}}>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                    }
                </div>                          
            </div>
        )
    }

}

PaymentOutput.propTypes = {
    payment: PropTypes.object.isRequired,
    output: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    payment: state.payment,
    withdraw: state.terminal.user.withdraw
})

const mapDispatchToProps = (dispatch) => ({
    output: (data) => dispatch(output(data)),
    getBalance: (data) => dispatch(getBalance(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentOutput);