import {
	GET_TERMINAL_CHART_HISTORY_REQUEST,
	GET_TERMINAL_CHART_HISTORY_FAILURE,
	GET_TERMINAL_CHART_HISTORY_SUCCESS,
} from '../../../actions/types'
import Store from '../../../store'
//import { serverError } from '../../../actions/handleActions'
import { fetchToAPI } from '../../../actions/optionsApp'
import Map from 'lodash/map'
import Includes from 'lodash/includes'

export default {
	onReady: cb => {
		//console.log('=====onReady running')	

		window.last_bar = {}

		setTimeout(() => cb(), 0)
	},
	searchSymbols: (userInput, exchange, symbolType, onResultReadyCallback) => {
		//console.log('====Search Symbols running')
	},
	resolveSymbol: (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) => {
		//console.log('======resolveSymbol running')

		let symbol_stub = {
			name: `${window.location.hostname}:${symbolName}`, //config.symbol_name,
			description: '',
			type: '',
			session: '24x7',
			timezone: 'Europe/Moscow',
			//ticker: localStorage.symbol, //config.symbol_name,
			exchange: 'Tradebox', //split_data[0],
			minmov: 1,
			pricescale: 10000, //100000000,
			has_intraday: true,
			//has_empty_bars: true,
			//intraday_multipliers: ['1', '60'],
			expired: false,
			expiration_date: false,
			supported_resolutions: ['1', '3', '5', '15', '30', '60', '120', '240', '1D'],
			volume_precision: 8,
			data_status: 'streaming',
		}

		console.log("TESR: 123")

		/*if (split_data[2].match(/USD|EUR|JPY|AUD|GBP|KRW|CNY|RUR/)) {
			symbol_stub.pricescale = 10000;
		}*/
		setTimeout(() => onSymbolResolvedCallback(symbol_stub), 0)
		
		
		// onResolveErrorCallback('Not feeling it today')

	},
	getBars: function(symbolInfo, resolution, from, to, onHistoryCallback, onErrorCallback, firstDataRequest) {
		//console.log('=====getBars start')
		// console.log(`Requesting bars between ${new Date(from * 1000).toISOString()} and ${new Date(to * 1000).toISOString()}`)

		let multiplier = 1
		let timespan = 'm'

		console.log(symbolInfo)
		console.log("test: " + resolution)

		if( resolution === 'W' || resolution === '1W' ) timespan = 'w'
		if( resolution === 'D' || resolution === '1D' ) timespan = 'd'
		if( Includes(['1', '3', '5', '15', '30', '45'], resolution) ){
			multiplier = parseInt( resolution )
			timespan = 'm'
		}
		if( Includes(['60', '120', '180', '240'], resolution) ){
			timespan = 'h'
			multiplier = parseInt( resolution ) / 60
		}
	
		Store.dispatch(fetchToAPI({
			url: '/terminal/history',
			data: {
				to: to * 1000,
				from: from * 1000,
				timespan: timespan,
				multiplier: multiplier,
				chart_id: localStorage.chartId
			},
			request: res => {
				return { 
					type: GET_TERMINAL_CHART_HISTORY_REQUEST,
					payload: res
				}
			},
			failure: res => {
				return { 
					type: GET_TERMINAL_CHART_HISTORY_FAILURE,
					payload: res
				}
			},
			success: res => {

				let bars = Map( res, ( t ) => {
					return {
						time: t[6],
						close: t[4], 
						open: t[1], 
						high: t[2], 
						low: t[3], 
						volume: t[5],
					}
				})

				console.log(bars)

				onHistoryCallback(bars, {noData: false})

				return { 
					type: GET_TERMINAL_CHART_HISTORY_SUCCESS,
					payload: res
				}
			},
			serverError: err => {
				//clearInterval(window.getBarsFetchInterval);

				//onHistoryCallback([], {noData: true});

				//Store.dispatch(updateStream(null));
				/*Store.dispatch(serverError({
					description: 'Рынок закрыт'
				}))*/
			},
		}))

	},


	subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback) => {
		//console.log('=====subscribeBars runnning')
		window.onRealtimeCallback = onRealtimeCallback
	},
	unsubscribeBars: subscriberUID => {
		//clearInterval(window.getBarsFetchInterval);
		//delete window.getBarsFetchInterval;
		//console.log('=====unsubscribeBars running')

		delete window.onRealtimeCallback;

		//stream.unsubscribeBars(subscriberUID)

	},
	calculateHistoryDepth: (resolution, resolutionBack, intervalBack) => {
		//optional
		//console.log('=====calculateHistoryDepth running - ' + resolution)
		// while optional, this makes sure we request 24 hours of minute data at a time
		// CryptoCompare's minute data endpoint will throw an error if we request data beyond 7 days in the past, and return no data
		//console.log(resolution)d
		//return resolution < 60 ? {resolutionBack: 'D', intervalBack: '1'} : undefined
		return { resolutionBack , intervalBack }
	},
	getMarks: (symbolInfo, startDate, endDate, onDataCallback, resolution) => {
		//optional
		//console.log('=====getMarks running')
	},
	getTimeScaleMarks: (symbolInfo, startDate, endDate, onDataCallback, resolution) => {
		//optional
		//console.log('=====getTimeScaleMarks running')
	},
	getServerTime: cb => {
		//console.log('=====getServerTime running')
	}
}