import { 
	PROFILE_CONFIRM_DELETE_REQUEST,
	PROFILE_CONFIRM_DELETE_FAILURE,
	PROFILE_CONFIRM_DELETE_SUCCESS,
} from '../actions/types';

const initialState = {

}

export default function(state = initialState, action ) {
	switch(action.type) {
		case PROFILE_CONFIRM_DELETE_REQUEST:
			return {
				...state,
				loading: true,
			}
		case PROFILE_CONFIRM_DELETE_FAILURE:
			return {
				...state,
				...action.payload,
				loading: false,
			}
		case PROFILE_CONFIRM_DELETE_SUCCESS:
			return {
				...state,
				...action.payload,
				loading: false,
			}
		default: 
			return state;
	}
}