import React from 'react'
import Moment from 'react-moment'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import CabinetTable from '../../elements/cabinetTable'
import CurrentChartInfo from '../../terminal/terminalHeader/currentChartInfo'

import { getList } from '../../../actions/deals'

import './index.scss'

class HistoryDeals extends React.Component {

	render() {

		const { 
			list,
			count,
			isLoaded
		} = this.props.deals;
		
		return (
			<div className="history-deals">		
				<CabinetTable 
					{...this.props}
					isLoaded={isLoaded}
					paginationNextBack={true}
					thead={
						<div className="deals__table-header">
							<div className="header__left">
								<div className="header__left-item">Инструмент</div>
								<div className="header__left-item">Информация</div>
								<div className="header__left-item">Котировка, открытие</div>
								<div className="header__left-item">Котировка, закрытие</div>
								<div className="header__left-item">Сумма</div>
							</div>
							<div className="header__right">
								<div className="header__right-item">Результат</div>
							</div>
						</div>
					}
					countItems={count}
					tbody={this.tableBody(list)}
					requestToServer={params => this.props.getList(params)}
				/>
			</div>
		)
	}

	tableBody(list) {
		if(!list.length) return false;

		let readyList = list.map((item, key) => {

			let income = parseFloat(item.income).toFixed(2);
			income = item.income > 0 ? `+${income}` : income;

			return (
				<div key={key} className="deals__table-item">
					<div className="item-left">
						<CurrentChartInfo data={item} allowYield={false}/>
						<div className="item-left-col information">
							<div className="information__value">{item.yield + 100}%</div>
							<div className="information__prop">{item.order_id}</div>
						</div>
						<div className="item-left-col quot_open">
							<div className="quot_open__mob_title">Котировка, открытие</div>
							<div className="quot_open__value">{parseFloat(item.spot).toFixed(2)}</div>
							<div className="quot_open__prop"><Moment locale="ru" format="YYYY/MM/DD HH:mm:ss" unix local ago>{item.opening_time}</Moment></div>
						</div>
						<div className="item-left-col quot_close">
							<div className="quot_close__mob_title">Котировка, закрытие</div>
							<div className="quot_close__value">{parseFloat(item.close_spot).toFixed(2)}</div>
							<div className="quot_close__prop"><Moment locale="ru" format="YYYY/MM/DD HH:mm:ss" unix local ago>{item.closing_time}</Moment></div>
						</div>
						<div className="item-left-col amount">
							<div className={`amount__icon amount__icon-${item.type}`}>
								<img src="/images/icons/profile/amount_index.png" alt=""/>
							</div>
							<div className="amount__value">{parseFloat(item.amount).toFixed(2)}$</div>
						</div>
					</div>
					<div className="item-right">
						<div className="item-right-col result">
							<div className={`result__value ${item.status}`}>{income}$</div>
						</div>
					</div>
				</div>
			)
		});

		return readyList;
	}

}

HistoryDeals.propTypes = {
	getList: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
	deals: state.deals,
})

const mapDispatchToProps = (dispatch) => ({
	getList: (data) => dispatch(getList(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(HistoryDeals);
