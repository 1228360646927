import React from 'react'
import moment from 'moment'
import { history } from '../../../../store'
import { connect } from 'react-redux'
import Moment from 'react-moment'
import 'react-day-picker/lib/style.css'
import {Select} from "../../../formElements/select";

import './index.scss'

import { historyIncreases, historyOutputs } from '../../../../actions/payment'

class PaymentHistory extends React.Component {

	constructor(props) {
		super(props);

	    let activePage = new URLSearchParams(props.location.search).get('number');

	    if(!activePage) {
	    	activePage = 1;
	    	history.replace(this.props.location.pathname + '?number='+activePage);
	    }

	    this.state = {
	    	dateTo: null,
	    	dateFrom: null,
	    	itemsCountPerPage: 10,
	    	activePage: +activePage,
			tabName: "increase"
	    };

	    this.handleFromChange = this.handleFromChange.bind(this);
	    this.handleToChange = this.handleToChange.bind(this);
	}

	handlePageChange(activePage) {

		this.setState({activePage: activePage});

		this.requestToServer(activePage);
	}

	handlePageNext() {
		let list = this.state.tabName === "increase" ? this.props.history_increases : this.props.history_outputs
		let activePage = this.state.activePage + 1
		if(activePage > Math.ceil(list.count/this.state.itemsCountPerPage)) return;
		history.replace(this.props.location.pathname + '?number='+activePage);

		this.setState({ activePage });

		this.requestToServer(activePage);
	}

	tableBody(list) {
		let readyList = list.map((item, key) => {
			let status;

			switch(item.status) {
				case 'in_progress': status = 'В обработке'; break;
				case 'failed': status = 'Не выполнено'; break;
				case 'success': status = 'Успешно'; break;
				default: break;
			}
			
			let bonus = +item.payment_bonus;

			return (
				<div key={key} className="history__table-item">
					<div className="item-col order">
						<div className="order__value">{item.order_id}</div>
					</div>
					<div className="item-col dateTime">
						<div className="dateTime__value">
							<Moment locale="ru" format="YYYY/MM/DD HH:mm:ss" unix local ago>{item.date}</Moment>
						</div>
					</div>
					<div className="item-col amount">
						<div className="amount__value">{parseFloat(bonus).toFixed(2)}$</div>
					</div>
					<div className="item-col amount">
						<div className="amount__value">{parseFloat(item.amount).toFixed(2)}$</div>
					</div>
					<div className="item-col status">
						<div className={"status__value status__label " + item.status}>{status}</div>
					</div>
				</div>
			)
		});

		return readyList;
	}

	handlePageBack() {
		let activePage = this.state.activePage - 1
		if(activePage < 1) return;
		history.replace(this.props.location.pathname + '?number='+activePage);

		this.setState({ activePage });

		this.requestToServer(activePage);
	}

	formatDate(date){
	    if (typeof date == "string"){
	        date = new Date(date);
	    }

	    var year = date.getFullYear();
	    var month = (1 + date.getMonth()).toString();
	    month = month.length > 1 ? month : '0' + month;

	    var day = date.getDate().toString();
	    day = day.length > 1 ? day : '0' + day;

	    return year+'-'+month+'-'+day;
	}

	requestToServer(newActivePage, newTabName) {
		let { dateFrom, dateTo, activePage, tabName } = this.state;

		dateTo = dateTo ? this.formatDate(dateTo) : null;
		dateFrom = dateFrom ? this.formatDate(dateFrom) : null;


		if((newTabName ? newTabName : tabName) === "increase") {
			this.props.historyIncreases({
				date_to: dateTo,
				date_from: dateFrom,
				active_page: newActivePage ? newActivePage : activePage
			});
		} else {
			this.props.historyOutputs({
				date_to: dateTo,
				date_from: dateFrom,
				active_page: newActivePage ? newActivePage : activePage
			});
		}

	}

	componentDidMount() {
		this.requestToServer();
	}

	showFromMonth() {
		const { dateFrom, dateTo } = this.state;

		if (!dateFrom) return;

		if (moment(dateTo).diff(moment(dateFrom), 'months') < 1) {
			this.dateTo.getDayPicker().showMonth(dateFrom);
		}
	}

	handleFromChange(dateFrom) {
		this.setState({ dateFrom });
	}

	handleToChange(dateTo) {
		this.setState({ dateTo }, this.showFromMonth);
	}

	applyFilter() {
		this.requestToServer();
	}

	changeOperationType(newTabName) {
		let { activePage } = this.state;

		this.setState({ tabName: newTabName });
		this.requestToServer(activePage, newTabName);
	}

	render() {
		
		const { 
			activePage,
			itemsCountPerPage,
			tabName
		} = this.state;

		const { 
			list,
			count
		} = tabName === "increase" ? this.props.history_increases : this.props.history_outputs;

		const tablist = [
			{ value: 'increase', name: 'Пополнение'},
			{ value: 'output', name: 'Вывод'},
		]

		console.log(list)
		return (
			<div className="payment-history">
				<div className="history__header">
					<div className="history__select">
						<Select options={tablist} value='Пополнение' className="historyOperationType_select" id="historyOperationType" onChange={(e)=> this.changeOperationType(e.value)} />

					</div>
					{
						count/itemsCountPerPage > 1 ? 
						<div className="history__pagination">
							<button className="history__pagination-prev" onClick={()=> {this.handlePageBack()}}>←&#160;&#160;Предыдущая</button>
							<div className="history__pagination-value">{`${activePage}/${Math.ceil(count/itemsCountPerPage)}`}</div>
							<button className="history__pagination-next"onClick={()=> {this.handlePageNext()}}>Следущая&#160;&#160;→</button>
						</div> : null
					}
				</div>
				<div className="body">
					<div className="history__content">
						<div className="history__table">
							<div className="history__table-header">
								<div className="header__item">Ордер</div>
								<div className="header__item">Дата и время</div>
								<div className="header__item">Бонус</div>
								<div className="header__item">Сумма</div>
								<div className="header__item">Статус</div>
							</div>
							<div className="history__table-body">{count !== 0 ? this.tableBody(list) : this.emptyTableBody()}</div>
						</div>
					</div>
					{this.props.children}
				</div>
			</div>
		)
	}

	emptyTableBody() {
		let { emptyTableBodyText } = this.props;

		emptyTableBodyText = emptyTableBodyText ? emptyTableBodyText : "У Вас еще нет операций";

		return (
			<tr className="empty-content">
				<td colSpan="100%">{emptyTableBodyText}</td>
			</tr>
		)
	}
}

const mapStateToProps = (state) => ({
	history_increases: state.payment.history_increases,
	history_outputs: state.payment.history_outputs
})

const mapDispatchToProps = (dispatch) => ({
	historyIncreases: (data) => dispatch(historyIncreases(data)),
	historyOutputs: (data) => dispatch(historyOutputs(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentHistory);
