import {
	RESET_PASSWORD_REQUEST,
	RESET_PASSWORD_FAILURE,
	RESET_PASSWORD_SUCCESS,
} from './types'
import { 
	fetchToAPIOld,
} from './optionsApp'

export const resetPassword = data => dispatch => {
	dispatch(fetchToAPIOld({
		url: '/auth/reset_password',
		data: data,
		request: res => {
			return { 
				type: RESET_PASSWORD_REQUEST,
				payload: res
			}
		},
		failure: res => {
			return { 
				type: RESET_PASSWORD_FAILURE,
				payload: res
			}
		},
		success: res => {
			return { 
				type: RESET_PASSWORD_SUCCESS,
				payload: res
			}
		}
	}))
}