import { 
	RESET_PASSWORD_REQUEST,
	RESET_PASSWORD_FAILURE,
	RESET_PASSWORD_SUCCESS,
} from '../actions/types';

const initialState = {

}

export default function(state = initialState, action ) {
	switch(action.type) {
		case RESET_PASSWORD_REQUEST:
			return {
				...state,
				loading: true,
			}
		case RESET_PASSWORD_FAILURE:
			return {
				...state,
				...action.payload,
				loading: false,
			}
		case RESET_PASSWORD_SUCCESS:
			return {
				...state,
				...action.payload,
				loading: false,
			}
		default: 
			return state;
	}
}