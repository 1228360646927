import { combineReducers } from 'redux'

import app from './app'
import site from './site'
import deals from './deals'
import payment from './payment'
import profile from './profile'
import terminal from './terminal'
import affiliate from './affiliate'
import authLogin from './authLogin'
import authConfirm from './authConfirm'
import authResetPassword from './authResetPassword'
import profileConfirmDelete from './profileConfirmDelete'
import authLogout from './authLogout'
import promocodes from './promocodes'
import setPageConf from './setPageConf'
import authRecover from './authRecover'
import authRegister from './authRegister'
import affiliatePayment from './affiliatePayment'
import affiliateInvitation from './affiliateInvitation'

export default combineReducers({
	app: app,
	site: site,
	deals: deals,
	payment: payment,
	profile: profile,
	terminal: terminal,
	affiliate: affiliate,
	authLogin: authLogin,
	authConfirm: authConfirm,
	authResetPassword: authResetPassword,
	profileConfirmDelete: profileConfirmDelete,
	pageConf: setPageConf,
	promocodes: promocodes,
	authLogout: authLogout,
	authRecover: authRecover,
	authRegister: authRegister,
	affiliatePayment: affiliatePayment,
	affiliateInvitation: affiliateInvitation,
});