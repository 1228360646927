import {
	CONFIRM_REQUEST,
	CONFIRM_FAILURE,
	CONFIRM_SUCCESS,
} from './types'
import { 
	fetchToAPIOld,
} from './optionsApp'

export const confirmEmail = data => dispatch => {
	dispatch(fetchToAPIOld({
		url: '/auth/confirm',
		data: data,
		request: res => {
			return { 
				type: CONFIRM_REQUEST,
				payload: res
			}
		},
		failure: res => {
			return { 
				type: CONFIRM_FAILURE,
				payload: res
			}
		},
		success: res => {
			return { 
				type: CONFIRM_SUCCESS,
				payload: res
			}
		}
	}))
}