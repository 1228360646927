export default [
	{
		category_url: 'trading-platform',
		category_name: 'Торговая платформа',
		category_desc: '',
		subcategories: [
			{
				name: '',
				pages: [
					{
						name: 'Что такое торговая платформа?',
						texts: [
							'Это онлайн-площадка, на которой трейдеры отслеживают котировки по разным видам активов и совершают сделки с использованием сервисов, которые предоставляет брокер.',
						]
					},
					{
						name: 'Почему стоит выбрать нас?',
						texts: [
							'Причины для выбора брокера отличаются от трейдера к трейдеру. Вот что может стать главным именно для вас:',
							'Легкий старт. Минимальный размер сделки составляет всего $1.',
							'Бесплатное обучение. Пользуйтесь готовыми стратегиями и услугами персональных менеджеров. ',
							'Круглосуточная поддержка. Наши специалисты готовы помочь в любом вопросе.',
							'Быстрый вывод средств. Выводите средства без комиссии наиболее удобным для вас способом.',
							'Гарантии. Сертифицированный брокер, а средства трейдеров защищены.',
						]
					},
					{
						name: 'Что такое демо-счет?',
						texts: [
							'На учебном счёте вы работаете в точно таких же условиях, как это делают трейдеры на реальном счёте. Учебный счет — это тренажёр для инвестора.',
						]
					},
					{
						name: 'Что такое таймфрейм?',
						texts: [
							'Это временной «масштаб» цен в торговой платформе. Если на линейном графике выбрать таймфрейм 10 минут, то можно увидеть часть графика цены за последние 10 минут. Если на графике японских свечей выбрать, к примеру, «5 минут», то каждая свеча будет показывать, как менялась цена именно за этот период. Если котировки выросли – свеча будет зеленой, если снизились – красной. На платформе используются временные интервалы от 1 минуты до года.',
						]
					},
					{
						name: 'Необходимо ли загружать на свой ПК какие-либо программы для торговли?',
						texts: [
							'Вы можете заниматься трейдингом через веб-платформу нашей компании сразу после регистрации аккаунта. Загружать дополнительные программы нет необходимости. ',
						]
					},
					{
						name: 'Что делать, если при загрузке платформы возникает системная ошибка?',
						texts: [
							'При возникновении технических ошибок в первую очередь рекомендуется очистить cache и cookies браузера. Также необходимо проверить, что используется последняя версия установленного браузера. Если ошибка не исчезает после совершенных действий и перезагрузки страницы, рекомендуем обратиться в нашу службу технической поддержки.',
						]
					},
					{
						name: 'Не открывается платформа',
						texts: [
							' Возможно, вам поможет смена браузера. Мы рекомендуем использовать последнюю версию Google Chrome. Возможно, возникла непредвиденная техническая проблема. Решить ее вам помогут специалисты технической поддержки.',
						]
					},
				]
			},
		]
	},
	{
		category_url: 'deals',
		category_name: 'Сделки',
		category_desc: '',
		subcategories: [
			{
				name: '',
				pages: [
					{
						name: 'Как посмотреть историю моих сделок?',
						texts: [
							'Информацию о последних сделках вы можете получить в разделе "Сделки". История всех сделок доступна в одноимённом разделе Личного кабинета пользователя.',
						]
					},
					{
						name: 'Как рассчитывается прибыль?',
						texts: [
							'Прибыль сделки в FTT-режиме может составлять до 92% от суммы инвестиции. Размер прибыли зависит от процента доходности, а также выбранной страйк-цены (контрольной цены). Чем больше процент доходности, тем выше ваша прибыль. Повысить или снизить доходность можно изменяя страйк-цену. <br/>Проценты доходности зависят от нескольких факторов: <br/>1. Условия поставщика ликвидности; <br/>2. Рыночная обстановка; <br/>3. Волатильность.',
						]
					},
					{
						name: 'Выбор условий сделки',
						texts: [
							'Возле графика актива находится меню выбора условий сделки. Чтобы открыть сделку, вам нужно выбрать: <br/>– Сумму сделки. От выбранного значения зависит размер потенциальной прибыли. <br/>– Время сделки. Вы можете установить её длительность (например, 12 минут).',
						]
					},
				]
			},
		]
	},
	{
		category_url: 'trading-time',
		category_name: 'Торговое время',
		category_desc: '',
		subcategories: [
			{
				name: '',
				pages: [
					{
						name: 'Торговая и котировочная сессии',
						texts: [
							'Котировочная сессия — период времени, в течение которого на платформу поступают и транслируются котировки. Однако сделки могут заключаться только в рамках чуть более короткой торговой сессии, которая является частью котировочной.<br/>Как правило, котировочная сессия начинается на 5-10 минут раньше и заканчивается на 5-10 минут позже торговой. Это сделано для того, чтобы защитить трейдеров от риска высокой волатильности в начале и конце котировочной сессии.<br/>Например, котировочная сессия акций Apple начинается в 13:30 GMT (в период летнего времени США), а заканчивается в 20:00. Торговая сессия по Apple стартует с пятиминутной задержкой, то есть 13:35. И заканчивается на 5 минут раньше — в 19:55.',
						]
					},
					{
						name: 'В какое время суток на валютном рынке ведется самая активная торговля?',
						texts: [
							'Активность торговли по валютам зависит от времени работы основных торговых площадок и повышается в моменты публикации важных экономических новостей. Самые активные торговые сессии — это европейская и американская. Европейская сессия начинается около 6:00 UTC и завершается ближе к 15:00 UTC. Американская сессия длится с 13:00 UTC до 22:00 UTC.<br/>Обратите внимание, что некоторые валютные пары и активы торгуются в ограниченном периоде времени. Режим торговли для каждого актива показан во вкладке "Условия торговли" меню "Активы".',
						]
					},
				]
			},
		]
	},
	{
		category_url: 'profile',
		category_name: 'Профиль',
		category_desc: '',
		subcategories: [
			{
				name: '',
				pages: [
					{
						name: 'Неверный пароль',
						texts: [
							'В первую очередь убедитесь, что пароль введен верно. <br/>Проверьте раскладку клавиатуры. Посмотрите, не нажат ли CAPS LOCK. <br/>Попробуйте набрать пароль в текстовом редакторе и скопируйте в соответствующее поле. <br/>Если все еще не удалось войти в аккаунт, вам нужно сменить пароль. <br/>Откройте окно для входа на платформу и кликните на «Сменить пароль»; введите e-mail, который использовался при регистрации и нажмите «Восстановить». Вы получите автоматически сгенерированный пароль для входа на платформу.',
						]
					},
					{
						name: 'Пользователь уже зарегистрирован или не найден',
						texts: [
							'Если вы получили такое уведомление, рекомендуем выполнить следующие действия. <br/>Убедитесь, что вы авторизуетесь тем же способом, которым регистрировались. Например, если вы прошли регистрацию через Facebook, при входе в аккаунт также выберите этот способ авторизации. <br/>Если вы прошли стандартную регистрацию с указанием e-mail и пароля, для авторизации используйте именно их. <br/>Все попробовали, но так и не смогли войти? В этом случае обращайтесь в службу поддержки. <br/>Консультант проверит, есть ли на платформе аккаунт с таким e-mail. <br/>Возможно, при регистрации вы указали неверный адрес почты.',
						]
					},
					{
						name: 'Аккаунт заблокирован',
						texts: [
							'Свяжитесь со службой поддержки удобным способом: через онлайн-чат или электронной почте. Консультант поможет разобраться в ситуации.',
						]
					},
				]
			},
		]
	},
	{
		category_url: 'payments-increases',
		category_name: 'Платежи',
		category_desc: '',
		subcategories: [
			{
				name: '',
				pages: [
					{
						name: 'Как пополнить счет?',
						texts: [
							'Перейдите на страницу пополнения и выберите способ оплаты. <br/>Укажите сумму пополнения. Вам может быть предложен бонус на депозит. Вы вправе отказаться от него. <br/>Если пополняете с карты, вы можете сохранить данные и в будущем пополнять счет в 1 клик. <br/>Минимальный депозит составляет всего $50, однако в некоторых странах он может отличаться.',
						]
					},
					{
						name: 'Когда придут деньги?',
						texts: [
							'Обычно деньги поступают на счет моментально. Если этого не произошло - обратитесь в службу поддержки. Консультант окажет необходимую помощь.',
						]
					},
				]
			},
		]
	},
	{
		category_url: 'payments-outputs',
		category_name: 'Выплаты',
		category_desc: '',
		subcategories: [
			{
				name: '',
				pages: [
					{
						name: 'Как вывести деньги со счета?',
						texts: [
							'Нажмите «Вывести» на платформе или в личном кабинете. <br/>Укажите сумму. Минимальная сумма вывода составляет $50, но для некоторых платёжных систем она может отличаться. <br/>В блоке «Доступные средства» вы найдете информацию о том, сколько можете вывести. <br/>Кликните на «Отправить заявку».',
						]
					},
					{
						name: 'Нужно ли предоставлять документы для вывода средств?',
						texts: [
							'Документы могут понадобиться только по запросу, заранее ничего предоставлять не нужно. Процедура необходима для того, чтобы дополнительно защитить средства на вашем счете. <br/>Если ваш аккаунт будет необходимо верифицировать, то на электронную почту вы получите инструкцию, как это сделать.',
						]
					},
					{
						name: 'Почему заказанная сумма выводится частями?',
						texts: [
							'Из-за особенностей работы платежных систем возможна следующая ситуация. <br/><br/>Вы запросили вывод, и на карту или электронный кошелек поступила только часть заказанной суммы. Заявка на выплату при этом все еще находится в обработке. <br/><br/>Не переживайте. У некоторых банков и платежных систем есть ограничения на максимальный платеж, поэтому большая сумма может зачисляться на счет меньшими частями. <br/><br/>Вы получите всю заказанную сумму, просто она будет выплачена в несколько этапов. <br/><br/>Обратите внимание: заказать новую выплату можно только после выполнения предыдущей. Оставить сразу несколько заявок на вывод нельзя.',
						]
					},
				]
			},
		]
	},
	{
		category_url: 'processing-time',
		category_name: 'Время обработки заявки ',
		category_desc: '',
		subcategories: [
			{
				name: '',
				pages: [
					{
						name: 'Когда деньги спишутся с баланса?',
						texts: [
							'Деньги с торгового счёта списываются после обработки заявки на вывод. <br/>Если заявка на вывод средств исполняется частями, средства с баланса также будут списываться постепенно.',
						]
					},
					{
						name: 'Почему пополнение моментальное, а вывод долгий?',
						texts: [
							' При пополнении мы сразу обрабатываем перевод и зачисляем средства на счет. <br/><br/>Операцию по выводу средств обрабатываем не только мы, но и ваш банк или платёжный метод. Из-за увеличения контрагентов в цепочке, может вырасти время исполнения заявки. Кроме того, платёжные системы придерживаются своих временных рамок на обработку транзацкий. <br/><br/>Например, на карту деньги зачисляются в среднем в течение 2 рабочих дней. Некоторые банки могут выполнять платеж до 30 дней. <br/>На электронные кошельки средства поступают почти сразу после обработки запроса на платформе. <br/><br/>Не переживайте, если в профиле видите статус «Выплата успешно произведена», а деньги не пришли. <br/><br/>Это значит, что мы их отправили, и теперь перевод обрабатывает банк или платежная система. К сожалению, мы не можем ускорить их работу.',
						]
					},
				]
			},
		]
	},

	


	{
		category_url: 'example_link',
		category_name: 'Название раздела',
		category_desc: 'Описание раздела',
		subcategories: [
			{
				name: 'Название части раздела',
				pages: [
					{
						name: 'название страницы',
						image: '/images/education/.png',
						texts: [
							'',
							'',
						]
					},
				]
			},
		]
	},

]