import {
	SET_PAGE_CONF,
	GET_APP_INFO_REQUEST,
	GET_APP_INFO_FAILURE,
	GET_APP_INFO_SUCCESS,
} from './types'
import config from '../config'
import { 
	handleError,
	serverError,
	handleSuccess
} from './handleActions'

export const getAppInfo = data => dispatch => {
	dispatch(fetchToAPI({
		url: '/default/info',
		data: data,
		request: res => {
			return { 
				type: GET_APP_INFO_REQUEST,
				payload: res
			}
		},
		failure: res => {
			return { 
				type: GET_APP_INFO_FAILURE,
				payload: res
			}
		},
		success: res => {
			return { 
				type: GET_APP_INFO_SUCCESS,
				payload: res
			}
		}
	}))
}


export const setPageConf = (state) => {
	return {
		type: SET_PAGE_CONF,
		payload: {
			showGoBack: !!state.showGoBack,
			name: state.name ? state.name : '',
			title: state.title ? state.title : '',
			urlGoBack: state.urlGoBack ? state.urlGoBack : ''
		}
	}
}



export const fetchToFileVerify = (obj) => dispatch => {
	dispatch(obj.request())
	
	var data = new FormData()
	data.append('passport', obj.data.passport)
	data.append('card', obj.data.card)
	data.append('token', localStorage.token)
	data.append('affiliateToken', localStorage.affiliateToken)

	fetch(config.serverUrl + obj.url, {
		method: 'POST',
		body: data
	})
	.then(res => res.json())
	.then(data => {
		if(data.error) {
			dispatch(handleError(data))
			if(obj.failure) dispatch(obj.failure(data))
			return false;
		}

		if(data.success && data.description) {
			dispatch(handleSuccess(data))
		}

		if(obj.success) dispatch(obj.success(data))
	})
	.catch(err => {
		if(obj.serverError) {
			obj.serverError();
			return false;
		}
		dispatch(serverError(err))
		console.log('Server Error!')
	})
}

export const fetchToAPI = (obj) => dispatch => {
	dispatch(obj.request())

	let link = document.location.href.split('/');

	let token = link[3] !== 'affiliate' ? {token: localStorage.token} : {};
	let affiliateToken = link[3] === 'affiliate' ? {affiliate_token: localStorage.affiliateToken} : {};

	fetch(config.apiUrl + obj.url, {
		method: obj.method ? obj.method : 'POST',
		/*mode: 'no-cors',
		headers: {
			'Content-Type': 'application/json;charset=utf-8'
		  },*/
		body: JSON.stringify({
			...token,
			...affiliateToken,
			...obj.data,
		})
	})
	.then(res => res.json())
	.then(data => {
		if(data.error) {
			dispatch(handleError(data))
			if(obj.failure) dispatch(obj.failure(data))
			return false;
		}

		if(data.success && data.description) {
			dispatch(handleSuccess(data))
		}

		if(obj.success) dispatch(obj.success(data))
	})
	.catch(err => {
		if(obj.serverError) {
			obj.serverError();
			return false;
		}
		dispatch(serverError(err))
		console.log('Server Error!')
	})
}

export const fetchToAPIOld = (obj) => dispatch => {
	dispatch(obj.request())

	let link = document.location.href.split('/');

	let token = link[3] !== 'affiliate' ? {token: localStorage.token} : {};
	let affiliateToken = link[3] === 'affiliate' ? {affiliate_token: localStorage.affiliateToken} : {};

	fetch(config.serverUrl + obj.url, {
		method: obj.method ? obj.method : 'POST',
		/*mode: 'no-cors',
		headers: {
			'Content-Type': 'application/json;charset=utf-8'
		  },*/
		body: JSON.stringify({
			...token,
			...affiliateToken,
			...obj.data,
		})
	})
	.then(res => res.json())
	.then(data => {
		if(data.error) {
			dispatch(handleError(data))
			if(obj.failure) dispatch(obj.failure(data))
			return false;
		}

		if(data.success && data.description) {
			dispatch(handleSuccess(data))
		}

		if(obj.success) dispatch(obj.success(data))
	})
	.catch(err => {
		if(obj.serverError) {
			obj.serverError();
			return false;
		}
		dispatch(serverError(err))
		console.log('Server Error!')
	})
}

export const cleanLocalStorageTerminal = data => dispatch => {
	localStorage.removeItem('token');
}
export const createAuthLocalStorageTerminal = data => dispatch =>  {
	dispatch(cleanLocalStorageTerminal())
	if(data.token) localStorage.setItem('token', data.token);
}


export const cleanLocalStorageAffiliate = data => dispatch => {
	localStorage.removeItem('affiliateToken');
}
export const createAuthLocalStorageAffiliate = data => dispatch =>  {
	dispatch(cleanLocalStorageAffiliate())
	if(data.affiliateToken) localStorage.setItem('affiliateToken', data.affiliateToken);
}