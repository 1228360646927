export default [
	{
		category_url: 'basics-trading',
		category_name: 'Основы трейдинга',
		category_desc: 'Добро пожаловать в обучение трейдингу. Трейдинг — это возможность заработать, но с ним могут быть связаны определенные финансовые риски. Вы научитесь понимать законы, по которым живет рынок, контролировать и даже уменьшать финансовые риски.',
		subcategories: [
			{
				name: 'О трейдинге',
				pages: [
					{
						name: 'Что такое трейдинг и зачем он нужен?',
						image: '/images/education/1.png',
						texts: [
							'Основная цель трейдинга — это получение прибыли на торговле активами. Это могут быть деньги, акции, сырьевые товары, криптовалюты и прочее.',
							'Заработать можно и на покупке, и на продаже актива. Когда трейдер покупает какой-то актив, он надеется, что актив вырастет в цене и его можно будет продать дороже.',
							'Когда трейдер продает актив, он рассчитывает, что сможет заработать на том, что выкупит актив по еще более низкой цене. Фактически трейдер продает то, чем не владеет.'
						]
					}, 
					{
						name: 'Как торговать успешно?',
						texts: [
							'Чтобы спрогнозировать дальнейшую рыночную стоимость активов и заработать на этом, трейдеры используют различные стратегии.',
							'Одна из возможных стратегий — это работа с новостями. Как правило, ее выбирают новички.',
							'Продвинутые трейдеры учитывают множество факторов, используют индикаторы, умеют прогнозировать тренды.',
							'Однако, даже у профессионалов случаются убыточные сделки. Страх, неуверенность, отсутствие терпения или желание заработать побольше приносят убытки даже опытным трейдерам. Держать эмоции под контролем помогают простые правила риск-менеджмента.'
						]
					}
				]
			},
			{
				name: 'Активы',
				pages: [
					{
						name: 'Что такое активы?',
						image: '/images/education/2.png',
						texts: [
							'Активы в мире трейдинга — это товар. К ним относятся деньги, ценные бумаги, сырье, индексы и цифровые валюты. На цену актива влияет объем сделок, который совершают по этому активу. Чем чаще актив покупают, тем сильнее растет цена. Если актив начинают активно продавать, то цена падает. Колебание стоимости актива за единицу времени называется волатильностью.',
							'Основная цель трейдеров — не приобрести товар, а получить прибыль за счёт разницы между ценой покупки и ценой продажи. Значит, зарабатывать можно и когда стоимость актива растет, и когда падает.',
							'Основных типов активов — 5: валютные пары, акции, индексы, сырьевые активы, криптовалюты.'
						]
					}, 
					{
						name: 'Акции и индексы',
						texts: [
							'Особенности торговли акциями и индексами схожи. Акции — это ценные бумаги, которые дают владельцу право на получение дивидендов и на часть имущества компании в случае ее продажи. А индексы отражают состояние рынка ценных бумаг.',
							'По сравнению с валютными парами акции и индексы являются менее рискованными инструментами за счет меньшей волатильности. Они лучше подходят для более длительных сделок и долгосрочного инвестирования.',
						]
					},
					{
						name: 'Сырьевые товары',
						texts: [
							'К сырьевым товарам относятся нефть, газ и металлы. Как правило, сырьевые товары показывают высокую волатильность и дают большое количество сигналов для совершения сделок внутри дня.',
						]
					}
				]
			},
			{
				name: 'Быки и медведи',
				pages: [
					{
						name: 'Быки',
						image: '/images/education/3.png',
						texts: [
							'В зависимости от стиля торговли трейдеры делятся на быков и медведей.',
							'Быки покупают активы в надежде на их будущий рост. Основная задача быка заключается в том, чтобы купить дёшево, а позднее продать дороже.',
							'Если желающих купить актив становится много, цена актива растет. Такой тренд называют «бычьим» — по аналогии с быком, который рогами подбрасывает жертву вверх.'
						]
					},
					{
						name: 'Медведи',
						texts: [
							'Медведи продают активы в надежде, что в будущем их цена снизится. Медведь продает актив дешево, дожидается снижения цены и выкупает проданный актив обратно по еще меньшей стоимости. В результате на счету трейдера также образуется разница в виде прибыли.',
							'Если медведей становиться больше быков, цена актива начинает падать. Такой тренд называется «медвежьим» — по аналогии с медведем, бьющим жертву лапой сверху вниз.',
							'Противостояние быков и медведей на любом активе происходит ежесекундно. Это изменение и фиксирует график.'
						]
					},
				]
			},
			{
				name: 'Время торгов на бирже',
				pages: [
					{
						name: 'Торговые сессии на форексе',
						image: '/images/education/4.png',
						texts: [
							'Валютный рынок доступен для трейдеров всего мира 24 часа в сутки. Это связано с тем, что участники торгов проживают в разных часовых поясах. Когда трейдеры в одной временной зоне заканчивают торговлю, на другом конце Земли торговая сессия только начинается. Исключения составляют выходные дни — суббота, воскресенье и национальные праздники, когда мировые биржи отдыхают. Однако это не относится к криптоактивам, которые торгуются 24 часа 7 дней в неделю.',
						]
					},
					{
						name: 'Торговые сессии на биржах',
						texts: [
							'Некоторые активы, такие как акции и ETF, доступны для торговли только в определённый период дня. Такая особенность объясняется режимом работы бирж, на которых эти инструменты торгуются.',
							'Чтобы ваша торговля была успешной, стоит учитывать, на какой торговой сессии вы работаете. Торговых сессий четыре — европейская, американская, азиатская и тихоокеанская, и каждая из них имеет свои особенности.'
						]
					},
					{
						name: 'Европейская сессия',
						texts: [
							'Европейская сессия открывается в 07:00 до 16:00 по Гринвичу. На эту сессию приходится большая часть операций на Форекс.',
							'Работа в Европейскую сессию подойдет тем, кто предпочитает активную торговлю. Такая ситуация идеальна для получения высокой прибыли, но помните — и сопряжена с высокими рисками.'
						]
					},
					{
						name: 'Американская сессия',
						texts: [
							'Американская сессия начинается в 12:00 и закрывается в 21:00 по Гринвичу. Активность на Американской сессии сравнима с активностью на Европейской. Считается, что это самое лучшее время для торговли по методу скальпинг .',
							'Особенностью скальпинга является заключение коротких сделок: до нескольких минут. Обычно, итогом является открытие большого количества сделок, каждая из которых приносит небольшой результат. Если вам близок такой стиль — это ваш выбор.'
						]
					},
					{
						name: 'Азиатская сессия',
						texts: [
							'Азиатская сессия открывается с 23:00 и закрывается в 08:00 по Гринвичу. Она характеризуется большими объёмами торгов. Помните: от того как развивается ситуация на азиатской сессии, зависит настроение всего торгового дня.',
						]
					},
					{
						name: 'Тихоокеанская сессия',
						texts: [
							'Тихоокеанская сессия стартует в 21:00 и заканчивается в 06:00 по Гринвичу.',
							'Это время считается самым спокойным — стоимость активов колеблется в узком ценовом диапазоне.'
						]
					},
					{
						name: 'Важные моменты',
						texts: [
							'Самая активная торговля — всегда в первые три часа работы сессии. Постарайтесь попасть именно в это время.',
							'Но и на спокойном рынке можно найти множество прибыльных моментов, если вы приверженец краткосрочной торговли.'
						]
					},
				]
			},
			{
				name: 'Самые опасные эмоции трейдера',
				pages: [
					{
						name: 'Психология трейдинга',
						image: '/images/education/5.png',
						texts: [
							'Для успешной торговли необходимо не только знать теорию, но и уметь управлять своими эмоциями. Под влиянием чувств трейдер может забыть о своей стратегии и совершить иррациональный поступок — например, продать позиции по невыгодной цене или ввязаться в убыточную сделку. Чтобы этого не произошло, важно научиться распознавать опасные эмоции и бороться с ними.',
						]
					},
					{
						name: 'Оптимизм',
						texts: [
							'Оптимизм мотивирует трейдеров покупать и верить, что восходящий тренд продолжится. Сложно торговать на фондовом рынке без оптимизма. При этом излишний оптимизм, не основанный на техническом анализе, является частой причиной потери денег из-за чрезмерной уверенности в торговле вблизи разворота тренда.',
						]
					},
					{
						name: 'Пессимизм',
						texts: [
							'Пессимизм заставляет людей продавать акции, проявлять осторожность и подозрительно относиться к восходящим трендам. Разумный пессимизм спасает инвестора от безрассудных покупков. Однако чрезмерные опасения мешают трейдеру получать прибыль и выгодно использовать позитивные рыночные ситуации.',
						]
					},
					{
						name: 'Страх',
						texts: [
							'Страх лишает трейдера возможности заработать, вынуждая его выходить из сделок и продавать позиции по слишком низкой цене. Беспокойные инвесторы часто предвидят нисходящий тренд, из-за чего не хотят покупать. Те, кто поддаются страху большую часть своего времени, вскоре понимают, что фондовый рынок не является их истинной целью.',
							'Именно поэтому всегда следует придерживаться торговой стратегии. Так вы будете уверены, что всё делаете правильно, и защитите себя от спонтанных решений на почве страха.'
						]
					},
					{
						name: 'Жадность',
						texts: [
							'Жадность — это хитрая эмоция, которой искушенный инвестор должен уметь управлять. Жадность часто приводит к обманчивой самоуверенности. Это подталкивает покупать вблизи разворота тренда.',
							'Когда трейдер испытывает жадность, он пытается получить слишком большую прибыль и отклоняется от стратегии. Жадность лишает человека способности трезво и объективно мыслить. Трейдер не выходит из сделки вовремя, пренебрегает адекватным риск-менеджментом. Всё это может привести к убыткам.',
							'Поэтому всегда придерживайтесь своего торгового плана, даже если вам покажется, что он не так прибылен. Конечно, этот совет актуален, если вы уверены в своей стратегии.'
						]
					},
					{
						name: 'Надежда',
						texts: [
							'Если страх отпугивает трейдера от действий, то надежда, напротив, удерживает его в убыточной сделке. Эти эмоции идут рука об руку. Если инвестиция успешна, надежда превращается в гордость, а в случае провала — в сожаление и страх. От пагубного влияния надежды помогает адекватная оценка своих сделок и хорошее понимание того, как работает рынок.',
						]
					},
					{
						name: 'Сожаление',
						texts: [
							'Испытывать сожаление по поводу упущенной прибыли или убыточной сделки нормально. Но если придавать грусти и разочарованию слишком большое значение, можно отвлечься и потерять мотивацию. Чтобы отключить эмоции, проанализируйте провальную сделку и поймите, что пошло не так, а затем постарайтесь отвлечься — например, на другие потенциальные возможности. Иногда это бывает сложно, однако трейдер должен уметь управлять своими эмоциями.',
						]
					},
				]
			},
			{
				name: 'Что такое торговые стратегии и для чего они нужны?',
				pages: [
					{
						name: 'Что такое торговая стратегия',
						image: '/images/education/6.png',
						texts: [
							'Чтобы прогнозировать цену того или иного актива и получить максимальную прибыль трейдеры используют торговые стратегии. Стратегия включает в себя инвестиционный план, факторы риска и временные характеристики.',
							'Существует много торговых стратегий, но все их можно поделить на два вида, которые отличаются по подходу к прогнозированию цены актива. Это может быть технический или фундаментальный анализ.'
						]
					},
					{
						name: 'Технический и фундаментальный анализ для торговых стратегий',
						texts: [
							'В случае со стратегиями, основанными на техническом анализе, трейдер выявляет рыночные закономерности. Для этого используются графические построения, фигуры и индикаторы технического анализа, а также свечные паттерны. Такие стратегии обычно подразумевают строгие правила открытия и закрытия сделок, установки ограничений на убыток и прибыль (ордера стоп-лосс и тейк-профит).',
							'В отличие от технического анализа, фундаментальный осуществляется «вручную». Трейдер вырабатывает свои правила и критерии отбора сделок и принимает решение, основываясь на анализе рыночных механизмов, курсе национальных валют, новостях экономики, росте выручки и рентабельности того или иного актива. Этот способ анализа используют более опытные игроки.'
						]
					},
					{
						name: 'Зачем нужны торговые стратегии',
						texts: [
							'Торговля на финансовых рынках без стратегии — это игра вслепую: сегодня повезёт, завтра нет. Большинство трейдеров, у которых нет определённого плана действий, забрасывают торговлю после нескольких неудачных сделок — они просто не понимают, как выйти в прибыль.',
							'Без системы с чёткими правилами входа и выхода из сделки трейдер легко может принять нерациональное решение. Новости рынка, советы друзей и экспертов, даже фаза Луны — да, есть исследования, связывающие положение Луны по отношению к Земле с циклами движения активов — могут заставить трейдера допустить ошибку или начать слишком много сделок.'
						]
					},
					{
						name: 'Плюсы работы с торговыми стратегиями',
						texts: [
							'Стратегия убирает из торговли эмоции — например, жадность, из-за которой трейдеры начинают тратить слишком много денег или открывают больше позиций, чем обычно. Изменения на рынке могут вызывать панику, и на этот случай у трейдера должен быть готовый план действий.',
							'Кроме того, использование стратегии помогает измерять и улучшать свои показатели. Если торговать хаотично, есть риск делать одни и те же ошибки. Поэтому важно собирать и анализировать статистику торгового плана, чтобы улучшать его и увеличивать прибыль.',
							'Стоит отметить, что не нужно полностью полагаться на торговые стратегии — важно всегда проверять информацию. Стратегия может хорошо работать в теории на основе прошлых рыночных данных, но это не гарантирует успеха в условиях реального времени.'
						]
					},
				]
			},
		]
	},

	{
		category_url: 'learn-how-to-trade-in-30-minutes',
		category_name: 'Научитесь торговать за 30 минут',
		category_desc: 'Интересуетесь, как зарабатывать на курсах валют, но не знаете с чего начать? Здесь вы найдете все о том, как получить прибыль на изменении цен и управлять своими инвестициями.',
		subcategories: [
			{
				name: 'Введение в трейдинг',
				pages: [
					{
						name: 'Что такое трейдинг и зачем он нужен?',
						image: '/images/education/7.png',
						texts: [
							'Когда речь идет о трейдинге, то подразумевается покупка или продажа валют, акций, товаров и других активов. Основная цель торговли — получение прибыли от операций, совершенных с активами.',
							'Суть торговли в том, чтобы заработать на разнице между ценой покупки и ценой продажи актива. Звучит просто, но трейдингу нужно учиться. Потому что трейдер получает только инструмент для получения дохода, а размер прибыли зависит лишь от самого трейдера.',
						]
					},
					{
						name: 'Сколько я могу заработать?',
						texts: [
							'Чтобы трейдинг приносил доход, нужно научиться улавливать тенденции, которые постоянно формируются на рынке. На цену актива влияют многие факторы.',
							'В итоге ваш заработок зависит от качества анализа: сможете ли вы определить удачный момент для заключения сделки.',
						]
					},
				]
			},
			{
				name: 'С чего начать?',
				pages: [
					{
						name: 'Почему изменилась цена?',
						image: '/images/education/8.png',
						texts: [
							'Цена актива, например валюты или акции, будет меняться в зависимости от спроса или предложения. Если спрос растет, или снижается предложение, то цена будет расти. И наоборот, падение спроса или рост предложения приведет к снижению цены.',
							'Инвесторы ожидают, что стоимость актива вырастет, поэтому хотят купить его по низкой цене и продать дороже позднее.',
						]
					},
					{
						name: 'Как заработать на изменении цены?',
						texts: [
							'Стоимость всех активов зависит от динамики спроса и предложений. А это значит, вы можете заработать как на повышении, так и на понижении цены любого актива.',
							'Заключайте сделки на понижение, когда цена актива падает. Заключайте сделки на повышение, когда цена актива растет.',
						]
					},
				]
			},
			{
				name: 'Что такое тренд?',
				pages: [
					{
						name: 'Как торговать на восходящем тренде?',
						texts: [
							'Восходящий тренд показывает тенденцию роста цены. Если график идет вверх, значит цена актива растет.',
							'На растущем рынке покупайте актив, чтобы продать его дороже позже.',
						]
					},
					{
						name: 'Как торговать на нисходящем тренде?',
						texts: [
							'Нисходящий тренд показывает тенденцию снижения цены. Если график идет вниз, значит цена актива падает.',
							'На падающем рынке продавайте актив, чтобы купить его дешевле позже.',
						]
					},
				]
			},
			{
				name: 'Как читать графики?',
				pages: [
					{
						name: 'Линейный график',
						image: '/images/education/9.png',
						texts: [
							'Анализ поведения графика позволяет обнаружить закономерности в движениях цены актива: вырастет она или упадет, продолжит двигаться в одном направлении или развернется.',
							'Самый простой для понимания тип графика — линейный. Каждое изменение цены актива на нем выглядит как точка. Точки соединяются и визуально представляют собой непрерывную ломаную линию.',
							'С помощью линейного графика очень удобно находить тренды и строить на этом стратегии торговли.',
						]
					},
					{
						name: 'название страницы',
						texts: [
							'На графике Японские свечи можно отследить более сложные сигналы — моменты разворота тренда и точки входа в сделку.',
							'Для определения наилучшего момента для совершения сделки на свечном графике ориентируйтесь на одну или несколько свечей. У каждой свечи есть цены открытия и закрытия, а также минимальная и максимальная цена. Комбинация этих четырех факторов определяет момент открытия или закрытия сделки.',
							'Иногда одна или несколько свечей образуют комбинации, которые называются свечными паттернами. Такие паттерны помогают определить, когда входить сделку, и как поведет себя цена в дальнейшем.'
						]
					},
					{
						name: 'Что такое бары?',
						texts: [
							'Бары – еще один тип графика, котировки на нем отображаются в виде вертикальных штрихов. Нижняя граница штриха соответствует минимальной цене, а верхняя граница — максимальной. Горизонтальный штрих слева от бара — цена открытия, горизонтальный штрих справа от бара — цена закрытия. Иногда цену открытия на баре можно не встретить.',
							'Бары схожи со свечным графиком и фактически содержат ту же информацию, что и японские свечи. Зеленые бары указывают, что цена актива росла. Красные — что падала.',
						]
					},
					{
						name: 'Хайкен Аши',
						texts: [
							'Хайкен Аши — особым образом построенный свечной график. Фактически, это усредненные японские свечи. Для построения свечи используется информация о ценах открытия/закрытия предыдущей свечи и информация об изменении цены в данный момент. Поэтому формально Хайкен Аши является индикатором, а не графиком.',
							'Хайкен Аши сглаживает колебания цены и упрощает анализ трендов, а значит находить моменты открытия и закрытия сделок с его помощью проще.',
						]
					},
					{
						name: 'Стоит ли торговать во флэте?',
						texts: [
							'Флэтом, или боковым трендом, на рынке называется ситуация, когда цена на протяжении какого-то периода времени не растет и не снижается.',
							'Движения цены во флэте, как правило, короткие. Поэтому для трейдера флэт — это сигнал о том, что сейчас не лучшее время для заключения сделок.',
							'Однако только 30% времени рынок находится в тренде и имея хорошую стратегию, можно получать прибыль в остальные 70% времени.',
							'Лучшая стратегия работы во флэте — торговля с помощью уровней поддержки и сопротивления. Если цена подходит к уровню поддержки сверху и касается его, открывайте сделку на повышение. Если цена подходит к уровню поддержки снизу и касается его, открывайте сделку на понижение.',
						]
					},
				]
			},
			{
				name: 'Уровни поддержки и сопротивления',
				pages: [
					{
						name: 'Что такое уровень поддержки?',
						image: '/images/education/10.png',
						texts: [
							'Уровень поддержки — уровень, после достижения которого стоимость актива разворачивается и начинает расти. Визуально представляет собой линию, которая образует преграду для дальнейшего снижения цен.',
							'Чаще всего он строится по ценовым минимумам, то есть по нижним пикам на графике изменения цены.',
						]
					},
					{
						name: 'Что такое уровень сопротивления?',
						texts: [
							'Уровень сопротивления — уровень, после достижения которого стоимость актива разворачивается и начинает падать. Визуально представляет собой линию, которая образует преграду для дальнейшего роста цены.',
							'Чаще всего он строится по ценовым максимумам, то есть по верхним пикам на графике изменения цены.',
						]
					},
					{
						name: 'Боковой тренд',
						texts: [
							'Пространство графика между уровнями поддержки и сопротивления называется ценовым каналом. Если график в канале движется горизонтально, то такое состояние рынка называется флэтом или боковым трендом.',
							'После отскока цены от уровня поддержки открывайте сделки на повышение. После отскока цены от уровня сопротивления открывайте сделки на понижение.',
						]
					},
					{
						name: 'Пробой уровня поддержки',
						texts: [
							'Периодически график выходит за пределы уровня поддержки. Если цена пробивает уровень и продолжает снижение, на рынке формируется нисходящий тренд.',
							'Когда график пересекает уровень поддержки сверху вниз, открывайте сделки на понижение.',
						]
					},
					{
						name: 'Пробой уровня сопротивления',
						texts: [
							'Периодически график выходит за пределы уровня сопротивления. Если цена пробивает уровень и продолжает движение вверх, на рынке формируется восходящий тренд.',
							'Когда график цены пересекает уровень сопротивления снизу вверх, открывайте сделки на повышение.',
						]
					},
					{
						name: 'Нисходящий тренд на уровнях поддержки и сопротивления',
						texts: [
							'Если ценовой канал между уровнями поддержки и сопротивления визуально направлен вниз, то на рынке сформировался нисходящий тренд.',
							'Торговать предпочтительнее в сторону наклона. Открывайте сделку на понижение, когда график цены коснется линии сопротивления.',
						]
					},
					{
						name: 'Восходящий тренд на уровнях поддержки и сопротивления',
						texts: [
							'Если ценовой канал между уровнями поддержки и сопротивления визуально направлен вверх, то на рынке сформировался восходящий тренд.',
							'Торговать предпочтительнее в сторону наклона. Открывайте сделку на повышение, когда график цены коснется линии поддержки сверху вниз.',
						]
					},
				]
			},
			{
				name: 'Риск-менеджмент',
				pages: [
					{
						name: 'Что такое риск-менеджмент?',
						image: '/images/education/11.png',
						texts: [
							'В ситуации неопределенности на рынке, например при равенстве между рыночными силами спроса и предложений, становится сложно постоянно принимать правильные решения о направлении движения цены. Поэтому сделки могут быть убыточными.',
							'Для того чтобы минимизировать убытки, существует несколько простых и надежных правил риск-менеджмента.',
							'Риск-менеджмент — это свод правил, которые определяют суммы сделок и ограничивают общий объем торговли трейдера. Риск-менеджмент нужен, чтобы сохранить депозит при любом исходе торговли.'
						]
					},
					{
						name: 'Торговля фиксированной суммой',
						texts: [
							'Вы определяете процент от депозита, как правило от 0.5 до 6%, и все время заключаете сделки на эту сумму.',
							'Если вы новичок, то придерживайтесь 0.5 - 1% депозита от суммы . Если вы уже имеете опыт в трейдинге, то можете заключать сделки на сумму от 2 до 6% от депозита.',
							'Важный момент: не заключайте сделки на 10% от депозита и выше. Если вы в одну секунду теряете 10 или 20% от суммы на счете, от такой потери сложно оправиться.'
						]
					},
					{
						name: 'Мартингейл',
						texts: [
							'В случае неудачной сделки вы увеличиваете сумму следующей сделки, чтобы перекрыть убытки. Как только сделка закрывается удачно, вы возвращаетесь к первоначальной сумме сделки и начинаете все заново.',
							'Например, вы открываете сделку на $1, и ваш прогноз оказывается неверным. На следующем шаге вы открываете сделку уже на $2, и она снова закрывается в минус. Затем вы увеличиваете сумму сделки до $5, и ваш прогноз оправдывается. Таким образом, вы не только перекроете убытки от предыдущих неудачных сделок, но и заработаете. Как правило, мартингейл содержит от 4 до 7 шагов.',
						]
					},
					{
						name: 'Парлай',
						texts: [
							'В случае успешной сделки, вы увеличиваете сумму следующей сделки. А когда сделка закрывается в минус, вы возвращаетесь к первоначальной сумме сделки и начинаете все заново.',
							'Как правило, система содержит 2-3 ступени. Суть этой методики в том, чтобы инвестировать прибыль в следующие сделки и быстрее наращивать депозит.',
						]
					},
				]
			},
		]
	},
	{
		category_url: 'how-to-read-candlestick-chart',
		category_name: 'Как читать свечной график',
		category_desc: 'Свечной анализ позволяет понять и прогнозировать ситуацию на рынке без использования торговых индикаторов. Торговля с использованием японских свечей включает в себя поиск определенных свечных моделей, которые помогают прогнозировать изменения цены. Модели свечей приобрели популярность благодаря информативности, наглядности и высокой точности сигналов.',
		subcategories: [
			{
				name: 'Японские свечи',
				pages: [
					{
						name: 'Как читать сигналы свечей?',
						texts: [
							'Одна или несколько свечей регулярно образовывают на графике комбинации, которые помогают принимать решения об открытии и закрытии сделок. Такие комбинации называются паттернами.',
							'Свечные паттерны делятся на модели, которые указывают на разворот тренда и модели, которые указывают на продолжение тренда. Все, что нужно — это дождаться появления на графике определенной модели и открыть сделку с соответствующим прогнозом.',
						]
					},
				]
			},
			{
				name: 'Односвечные паттерны',
				pages: [
					{
						name: 'Как торговать с помощью паттернов из одной свечи?',
						image: '/images/education/12.png',
						texts: [
							'Существуют модели японских свечей, которые состоят из одной свечи. В зависимости от того, как выглядит свеча и в какой цвет окрашена, такие модели дают сигналы о развороте или продолжении тренда.',
							'Чтобы получать наиболее точные сигналы, анализируйте паттерны из одной свечи на старших таймфреймах — от 1 часа и выше. Для подтверждения сигналов дополнительно анализируйте другие паттерны, о которых поговорим ниже.',
						]
					},
					{
						name: 'Молот',
						image: '/images/education/13.png',
						texts: [
							'Паттерн «Молот» — это индикатор разворота тренда.',
							'Визуально такая свеча похожа на молот — с маленьким телом любого цвета и с длинной нижней тенью. Верхняя тень практически отсутствует. При этом нижняя тень свечи обычно в 2 раза длиннее, чем тело свечи.',
							'При такой ситуации открывайте сделку на повышение, если цена до этого падала, и на понижение, если цена до этого росла.',
						]
					},
					{
						name: 'Падающая звезда',
						image: '/images/education/14.png',
						texts: [
							'Паттерн «Падающая звезда» — это индикатор разворота тренда.',
							'Визуально такая свеча похожа на перевернутый молот — с маленьким телом любого цвета и с длинной верхней тенью: нижняя тень практически отсутствует. При этом верхняя тень как правило более чем в 2 раза длиннее, чем тело свечи.',
							'При такой ситуации открывайте сделку на повышение, если цена до этого падала, и на понижение, если цена до этого росла.'
						]
					},
					{
						name: 'Дожди',
						image: '/images/education/15.png',
						texts: [
							'"Доджи" — это индикатор закрытия позиции из-за неопределенности на рынке. Как правило, формируется при равенстве между рыночными силами спроса и предложений.',
							'Визуально представляет собой крест: цена открытия примерно равна цене закрытия, а обе тени — длинные.',
							'При такой ситуации решение о направлении сделки принимайте в контексте предыдущей динамики цены.'
						]
					},
				]
			},
			{
				name: 'Паттерны из двух свечей',
				pages: [
					{
						name: 'Как торговать с помощью паттернов из двух свечей?',
						image: '/images/education/16.png',
						texts: [
							'Часто на графике можно встретить свечные модели, состоящие из одной зеленой и одной красной свечей. Они располагаются друг за другом определенным образом и дают сигнал о том, что нужно открывать сделку на повышение или сделку на понижение.',
							'Преимущество таких моделей состоит в том, что они дают опережающие сигналы. Точность сигналов повышается, когда на рынке четко выраженный восходящий или нисходящий тренд.',
						]
					},
					{
						name: 'Бычье поглощение',
						image: '/images/education/17.png',
						texts: [
							'"Бычье поглощение" — комбинация, где границы тела зеленой свечи больше, чем границы тела красной. Зеленая свеча следует за красной и полностью ее перекрывает. Это означает, что цена актива выросла сильнее, чем снизилась до этого.',
							'Как правило, этот паттерн дает сигнал о завершении нисходящего тренда и начале восходящего тренда.',
						]
					},
					{
						name: 'Медвежье поглощение',
						image: '/images/education/18.png',
						texts: [
							'"Медвежье поглощение" — это комбинация, где границы тела красной свечи больше, чем границы тела зеленой. Красная свеча следует за зеленой и полностью ее перекрывает. Это означает, что цена актива снизилась сильнее, чем выросла до этого.',
							'Как правило, этот паттерн дает сигнал о завершении восходящего тренда и начале нисходящего тренда.',
						]
					},
					{
						name: 'Просвет в облаках',
						image: '/images/education/19.png',
						texts: [
							'"Просвет в облаках" — это комбинация, где тело зеленой свечи начинается ниже тела красной свечи. Зеленая свеча следует за красной, при этом цена закрытия зеленой свечи находится в районе верхней половины тела красной свечи.',
							'Как правило, этот паттерн дает сигнал о начале восходящего тренда. "Просвет в облаках" работает аналогично паттерну "Бычье поглощение", но не является таким сильным. Для подтверждения сигнала анализируйте также другие паттерны.',
						]
					},
					{
						name: 'Завеса из темных облаков',
						image: '/images/education/20.png',
						texts: [
							'"Завеса из темных облаков" — комбинация, где тело красной свечи начинается ниже тела зеленой свечи. Красная свеча следует за зеленой, при этом цена закрытия красной свечи находится в районе нижней половины тела зеленой свечи.',
							'Как правило, этот паттерн дает сигнал о начале нисходящего тренда. "Завеса из темных облаков" работает аналогично паттерну "Медвежье поглощение", но не является таким сильным. Для подтверждения сигнала анализируйте также другие паттерны.',
						]
					},
					{
						name: 'Бычий харами',
						image: '/images/education/21.png',
						texts: [
							'"Бычий харами" — комбинация, где за длинной красной свечей следует более короткая зеленая свеча. При этом тело зеленой свечи не выходит за границы тела красной.',
							'Как правило, этот паттерн дает сигнал о начале восходящего тренда. Чтобы точнее определить тренд, анализируйте паттерн совместно с линией поддержки.',
						]
					},
					{
						name: 'Медвежий харами',
						image: '/images/education/22.png',
						texts: [
							'"Медвежий харами" — комбинация, где за длинной зеленой свечей следует более короткая красная свеча. При этом тело красной свечи не выходит за границы тела зеленой свечи.',
							'Как правило, этот паттерн дает сигнал о начале нисходящего тренда. Чтобы точнее определить тренд, анализируйте паттерн совместно с линией сопротивления.',
						]
					},
				]
			},
			{
				name: 'Паттерны из трех свечей. Средняя точность сигналов',
				pages: [
					{
						name: 'Как применять паттерны из трёх свечей со средней точностью сигналов',
						image: '/images/education/23.png',
						texts: [
							'Как и высокоточные паттерны, комбинации из трёх свечей со средней точностью сигналов могут указывать на продолжение или разворот тренда. Они позволяют эффективнее анализировать рынок, чем паттерны из одной или двух свечей.',
							'Тем не менее, следует с осторожностью использовать паттерны этой группы в торговле. Рекомендуется сочетать их с другими инструментами технического анализа, чтобы подтверждать сигналы.',
						]
					},
					{
						name: 'Три южных звезды',
						image: '/images/education/24.png',
						texts: [
							'Паттерн "Три южных звезды" формируется на нисходящем тренде.',
							'Он состоит из трёх красных свечей. Каждая последующая свеча короче предыдущей. Кроме того, минимальная цена повышается от свечи к свече.',
							'"Три южных звезды" указывает на то, что нисходящий тренд становится слабее, и возможен бычий разворот. Если на графике сформировался этот паттерн, цена может начать расти после падения.'
						]
					},
					{
						name: 'Бычья звезда Доджи',
						image: '/images/education/25.png',
						texts: [
							'Паттерн "Бычья звезда Доджи" начинается с длинной красной свечи.',
							'За ней следует свеча дожи. Она расположена ниже предыдущей свечи, и между ними есть небольшой разрыв вниз.',
							'Третья свеча — зелёного цвета. Её тело больше тела первой красной свечи и практически перекрывает его.',
							'"Бычья звезда Доджи" указывает на бычий разворот тренда. Если на графике сформировался этот паттерн, цена как правило начинает расти после падения.',
							'Обратите внимание: входить в рынок по паттерну "Бычья звезда Доджи" можно, только если он сформировался на нисходящем тренде.'
						]
					},
					{
						name: 'Бычье замедление',
						image: '/images/education/26.png',
						texts: [
							'"Бычье замедление" — это обратный вариант паттерна "Три южных звезды". "Бычье замедление" формируется на восходящем тренде.',
							'Он состоит из трёх зелёных свечей. Первая и вторая свеча примерно одного размера, а третья — значительно короче их. Цены открытия и закрытия повышаются от свечи к свече.',
							'"Бычье замедление" указывает на то, что восходящий тренд ослабевает, и цена может развернуться вниз. Если на графике сформировался этот паттерн, вероятно падение цены после роста.'
						]
					},
					{
						name: 'Нижний разрыв метода трех',
						image: '/images/education/27.png',
						texts: [
							'"Нижний разрыв метода трех" формируется на нисходящем тренде.',
							'Он начинается с двух длинных красных свечей, между которыми есть разрыв вниз.',
							'Третья свеча — зелёная, и её тело перекрывает разрыв между первыми двумя свечами.',
							'Если на графике сформировался паттерн "Нижний разрыв метода трех", как правило, нисходящий тренд продолжается.'
						]
					},
				]
			},
			{
				name: 'Паттерны из трех свечей. Высокая точность сигналов',
				pages: [
					{
						name: 'Как применять паттерны из трёх свечей с высокой точностью сигналов',
						image: '/images/education/28.png',
						texts: [
							'Паттерны из трёх свечей дают самые точные сигналы по сравнению с комбинациями из одной или двух японских свечей. По этой причине трейдеры чаще всего используют именно этот вид свечных комбинаций.',
							'Паттерны из трёх свечей указывают на продолжение тренда или разворот цены актива. Их можно применять как самостоятельно, так и в комбинации с другими инструментами теханализа.',
						]
					},
					{
						name: 'Три дня изнутри вверх',
						image: '/images/education/29.png',
						texts: [
							'"Три дня изнутри вверх" — это расширенный вариант паттерна "Бычий харами".',
							'В этом паттерне первая свеча — красная.',
							'За ней следует более короткая зелёная свеча, причём её тело не выходит за границы тела красной свечи.',
							'Третья свеча — тоже зелёная, и цена её закрытия выше, чем у предыдущей зелёной свечи.',
							'Как правило, паттерн "Три дня изнутри вверх" подтверждает, что тренд развернулся, и цена начала расти после падения.'
						]
					},
					{
						name: 'Утренняя звезда',
						image: '/images/education/30.png',
						texts: [
							'"Утренняя звезда" начинается с длинной красной свечи.',
							'За ней следует короткая свеча красного или зелёного цвета. У неё маленькое тело, потому что она закрылась рядом с ценой, на которой открылась.',
							'Третья свеча — зелёная, и её тело перекрывает большую часть тела первой, длинной красной свечи.',
							'"Утренняя звезда" как правило указывает на бычий разворот тренда. Если этот паттерн сформировался на графике, цена скорее всего будет расти после падения.',
							'Обратите внимание: чем больше тело третьей зелёной свечи, тем сильнее сигнал.'
						]
					},
					{
						name: 'Утренняя звезда доджи',
						image: '/images/education/31.png',
						texts: [
							'«Утренняя звезда доджи» — это бычий паттерн, состоящий из трех свечей. Первая свеча красного цвета — заключительная свеча на снижающемся тренде. Вторая свеча — звезда. Это свеча с маленьким телом и более длинными тенями. Она может быть любого цвета. Третья свеча — зеленая. Эта свеча говорит о завершении модели и о начале бычьего тренда. В паттерне «Утренняя звезда доджи» вторая свеча — свеча доджи. У нее отсутствует (или практически отсутвует) тело, а тени сверху и снизу длинные.',
							'Благодаря доджи модель «Утренняя звезда доджи» дает более сильный сигнал. Он указывает, что цена скорее всего развернулась наверх и будет расти после падения.',
						]
					},
					{
						name: 'Три внешних дня вверх',
						image: '/images/education/32.png',
						texts: [
							'"Три внешних дня вверх" — это расширенный вариант паттерна "Бычье поглощение".',
							'В паттерне "Три внешних дня вверх" первая свеча — красная.',
							'За ней следует зелёная, и её тело больше тела предыдущей свечи, то есть вторая свеча поглощает первую.',
							'Третья свеча — тоже зелёная. Цена её закрытия выше, чем у предыдущей свечи.',
							'Как правило, паттерн "Три внешних дня вверх" указывает на бычий разворот тренда и начало роста цены после падения.'
						]
					},
					{
						name: 'Три белых солдата',
						image: '/images/education/33.png',
						texts: [
							'Паттерн «Три белых солдата» состоит из трех зеленых свечей. Цена открытия каждой следующей свечи находится в границах тела предыдущей свечи. Цена закрытия последовательно возрастает от свечи к свече.',
							'Модель «Три белых солдата» в большинстве случаев говорит о продолжении восходящей тенденции.',
						]
					},
					{
						name: 'Три бычьи звезды',
						image: '/images/education/34.png',
						texts: [
							'"Три бычьи звезды" — это обратный вариант паттерна "Три медвежьи звезды". "Три бычьи звезды" появляется на нисходящем тренде.',
							'Он состоит из трёх свечей доджи. Первая и третья свечи находятся примерно на одном уровне, а вторая — ниже них.',
							'Этот паттерн указывает на ослабление нисходящего тренда. Скорее всего, цена сделает бычий разворот и начнёт расти после падения.',
							'За счёт сразу трёх свечей доджи паттерн "Три бычьи звезды" даёт сильный сигнал. Если на графике появился этот паттерн, на него обязательно нужно обратить внимание.'
						]
					},
					{
						name: 'Вечерняя звезда',
						image: '/images/education/35.png',
						texts: [
							'Этот паттерн является противоположностью паттерна "Утренняя звезда".',
							'"Вечерняя звезда" начинается с длинной зелёной свечи.',
							'Следующая свеча — короткая, она может быть и красной, и зелёной.',
							'Третья свеча — красная, и её тело перекрывает большую часть тела первой, зелёной свечи.',
							'Чаще всего "Вечерняя звезда" указывает на медвежий разворот тренда. Если на графике появился этот паттерн, цена может начать падать после роста.',
							'Обратите внимание на 2 момента.',
							'1. Чем больше тело третьей красной свечи, тем сильнее сигнал.',
							'2. Паттерн "Вечерняя звезда" становится сигналом ко входу в рынок, только если он формируется после восходящего тренда.',
						]
					},
					{
						name: 'Вечерняя звезда доджи',
						image: '/images/education/36.png',
						texts: [
							'Этот паттерн схож с паттерном "Вечерняя звезда". Отличается "Вечерняя звезда доджи" тем, что вторая свеча в нём — короткая свеча доджи, после которой чаще всего тренд разворачивается.',
							'Благодаря доджи паттерн "Вечерняя звезда доджи" даёт более сильный сигнал. Он, как правило, указывает, что цена развернулась вниз и будет падать после роста.',
						]
					},
					{
						name: 'Три внешних дня вниз',
						image: '/images/education/37.png',
						texts: [
							'Этот паттерн является обратным, противоположным вариантом паттерна "Три внешних дня вверх".',
							'В "Три Три внешних дня вниз" первая свеча зелёного цвета.',
							'Средняя свеча — красная, причём её тело больше тела предыдущей, то есть вторая свеча поглощает первую.',
							'Третья свеча — тоже красная, и цена её закрытия ниже, чем у средней.',
							'Паттерн "Три внешних дня вниз" — это расширенный вариант паттерна "Медвежье поглощение". Как правило, "Три внешних дня вниз" даёт сигнал, что тренд сделал медвежий разворот, и цена начинает падать после роста.',
						]
					},
					{
						name: 'Метод трех падающих',
						image: '/images/education/38.png',
						texts: [
							'Паттерн «Метод трех падающих» дает сигнал, что нисходящий тренд продолжится. Несмотря на название, он состоит не из трех, а из пяти свечей.',
							'«Метод трех падающих» начинается с длинной красной свечи.',
							'За ней следует серия из трех коротких зеленых свечей, которые последовательно повышаются.',
							'Эти три свечи, включая их тени, должны находиться в границах первой красной свечи.',
							'После серии зеленых свечей формируется большая красная свеча, цена закрытия которой ниже цены закрытия первой красной свечи.',
							'Если на графике сформировался этот паттерн, как правило, нисходящий тренд продолжается.'
						]
					},
					{
						name: 'Три дня изнутри вниз',
						image: '/images/education/39.png',
						texts: [
							'Этот паттерн — обратный вариант паттерна "Три дня изнутри вверх".',
							'"Три дня изнутри вниз" начинается с зелёной свечи.',
							'За ней следует короткая красная свеча, и её тело не выходит за границы тела первой зелёной.',
							'Третья свеча — тоже красная, причём цена её закрытия ниже, чем у предыдущей красной.',
							'Паттерн "Три дня изнутри вниз" — это расширенный вариант паттерна "Медвежий харами". Как правило, паттерн "Три дня изнутри вниз" подтверждает медвежий разворот тренда, и цена начинает снижаться после роста.',
						]
					},
					{
						name: 'Три черные вороны',
						image: '/images/education/40.png',
						texts: [
							'Паттерн "Три черные вороны" является противоположностью паттерна "Три белых солдата".',
							'"Три черные вороны" состоит из трёх красных свечей, которые последовательно снижаются. Цена открытия каждой следующей свечи находится в границах тела предыдущей свечи.',
							'Если тело первой красной свечи в паттерне находится ниже максимума зелёной свечи, которая предшествует "Три черные вороны", это усиливает сигнал.',
							'Как правило, "Три черные вороны" указывает, что тренд развернулся, и цена будет падать после роста.',
							'Обратите внимание: входить в рынок по этому паттерну можно только в случае, если "Три черные вороны" сформировался после восходящего тренда.',
						]
					},
					{
						name: 'Три медвежьи звезды',
						image: '/images/education/41.png',
						texts: [
							'"Три медвежьи звезды" состоит из трёх свечей доджи. Первая и третья свечи находятся примерно на одном уровне, а центральная — выше них.',
							'Этот паттерн указывает на то, что восходящий тренд стал слабее, и скорее всего произойдёт медвежий разворот. Цена начнёт падать после роста.',
							'Благодаря сразу трём свечам доджи "Три медвежьи звезды" даёт очень сильный сигнал. Если такой паттерн сформировался на графике, на него обязательно нужно обратить внимание.'
						]
					},
				]
			},
		]
	},
	{
		category_url: 'fundamental-analysis-for-beginners',
		category_name: 'Фундаментальный анализ для начинающих',
		category_desc: '',
		subcategories: [
			{
				name: 'Что такое фундаментальный анализ?',
				pages: [
					{
						name: 'Почему нужно использовать фундаментальный анализ?',
						texts: [
							'Для того чтобы получить полноценную картину рынка, необходимо использовать в торговле не только технический, но и фундаментальный анализ.',
							'Фундаментальный анализ — это анализ влияния разных экономических факторов на валюты стран, сырьё, акции компаний и индексы.',
							'Если технический анализ направлен, в первую очередь, на краткосрочную (текущую) оценку ситуации, то фундаментальный – на более длительную и перспективную. Одновременно использование в трейдинге двух видов анализа позволяет торговать максимально эффективно.'
						]
					},
					{
						name: 'Торговля на новостях',
						texts: [
							'Трейдинг на новостях является одним из уникальных направлений фундаментального анализа. Этот вид торговли даёт возможность приличного заработка не только опытным, но и начинающим трейдерам.',
							'Очень важно отслеживать новости заранее, чтобы знать, когда именно выйдет та или иная публикация и на какие активы она окажет влияние. Благодаря этим знаниям можно принимать решения заблаговременно и даже выставлять отложенные ордера на открытие сделок.',
							'Отслеживать новости и торговать на новостях удобнее всего по экономическому календарю.'
						]
					},
				]
			},
			{
				name: 'Микроэкономические показатели Европы',
				pages: [
					{
						name: 'Страны Европы с самой сильной экономикой',
						texts: [
							'Это Германия, Австрия, Франция, Бельгия, Дания, Швеция и Финляндия. До 2020 года в состав ЕС входила Великобритания, которая также является одной из самых сильных мировых экономик.',
							'Мы рекомендуем обращать особо пристальное внимание на макроэкономические показатели Великобритании, Германии, Франции и в целом по ЕС, которые публикуются в экономическом календаре. Они позволяют оценить общее состояние европейской экономики, которое будет влиять на курс евро.',
						]
					},
					{
						name: 'На какие показатели нужно обращать внимание?',
						texts: [
							'Уровень безработицы в Великобритании, Германии, Франции и ЕС — чем выше безработица, тем ниже курс евро.',
							'Индекс потребительских цен (CPI) в ЕС — показатель, который характеризует уровень инфляции. Чем выше индекс, тем выше курс евро и наоборот.',
							'ВВП стран Евросоюза – это стоимость всей экономики ЕС. Чем выше темпы роста ВВП, тем выше будет курс евро.',
							'Решения Европейского центрального банка о повышении/понижении ключевой ставки: рост ключевой ставки будет означать рост евро, а снижение ключевой ставки, наоборот, оказывает негативное влияние на курс единой европейской валюты.',
						]
					},
				]
			},
			{
				name: 'Микроэкономические показатели США',
				pages: [
					{
						name: 'Почему нужно разбираться в экономике США?',
						texts: [
							'Доллар США является международной валютой расчётов. США имеет торговые отношения со многими странами мира, покупая (потребляя) огромное количество товаров и услуг. Таким образом, если экономика США растёт, то экономики большинства стран также будут расти, и наоборот — падение американской экономики может привести к глобальному экономическому кризису.',
							'Экономика США – это также одна из самых сложных экономик. В течение месяца в США публикуется огромное количество макроэкономических данных. Это дает самое полное представление о том, что происходит в стране, а также позволяет прогнозировать дальнейшее поведение валют, акций, индексов, ETF и других финансовых активов.',
							'Одними из самых главных показателей являются уровень безработицы США и Non-farm payrolls.'
						]
					},
					{
						name: 'Non-farm payrolls. Что это?',
						texts: [
							'Non-farm payrolls — важнейший индикатор, который публикуется в США в первую пятницу каждого месяца. Он показывает количество людей, занятых в несельскохозяйственном секторе экономики. Чем выше показатель Non-farm — тем лучше, поскольку это говорит о дальнейшем росте экономики. И наоборот — чем меньше людей занято, тем хуже чувствует себя экономика.',
							'Для торговли по Non-farm можно выбрать любую валютную пару с американским долларом. Самая подходящая валютная пара — EUR/USD.',
							'В случае если опубликованные данные Non-farm payrolls выше прогнозных значений, то американский доллар будет расти (пара EUR/USD падать), и наоборот. Движение пары при этом очень сильное. Можно открыть сделку на 2-3 минуты в момент выхода новости по направлению движения цены.'
						]
					},
				]
			},
			{
				name: 'Микроэкономические показатели Азии',
				pages: [
					{
						name: 'Страны Азии с самой сильной экономикой',
						texts: [
							'В первую очередь, к странам Азии с самой сильной экономикой можно отнести Китай и Японию. Темпы роста ВВП этих стран высокие, и они имеют прочные торговые связи с другими странами, в первую очередь с США.',
						]
					},
					{
						name: 'На какие показатели нужно обращать внимание?',
						image: '/images/education/.png',
						texts: [
							'Помимо стандартных индикаторов этих стран, таких как индекс потребительских цен (инфляция), уровень безработицы, ВВП и решения по процентной ставке, рекомендуем обратить пристальное внимание на отчёт Tankan от Японского центрального банка, который отражает состояние японской экономики. Чем выше индекс Tankan — тем выше курс японской йены. Этот отчёт публикуется Банком Японии раз в квартал.',
							'',
						]
					},
				]
			},
			{
				name: 'Микроэкономические показатели Австралии и Новой Зеландии',
				pages: [
					{
						name: 'Почему нужно разбираться в экономике Австралии и Новой Зеландии?',
						texts: [
							'Австралия и Новая Зеландия относятся к странам с развитой экономикой. Эти страны имеют устойчивые темпы роста ВВП, а также сильные прочные торговые связи с остальными странами. Анализ показателей этих стран идентичен фундаментальному анализу других экономик. Необходимо обращать пристальное внимание на уровень безработицы, ключевую ставку, индекс производственной активности и темпы роста ВВП.',
						]
					},
					{
						name: 'От чего зависят экономики Австралии и Новой Зеландии?',
						texts: [
							'Порядка 30% экспорта Новой Зеландии приходится на молоко и молочную продукцию. Таким образом, цены на молоко на мировых рынках будут напрямую влиять на ситуацию с NZD. Два раза в месяц, по вторникам, Global Dairy Trade публикует индекс цен на молоко и молочную продукцию.',
							'Рост этого индекса положительно сказывается на валюте Новой Зеландии. Если индекс снижается (рост цен на молочную продукцию замедляется) — это негативный фактор для NZD.',
							'Экономика Австралии зависит от минерального топлива (нефть, каменный уголь, мазут), на которое приходится более 30% экспорта. Таким образом, рост цен на топливо будет положительно сказываться на экспорте страны.'
						]
					},
				]
			},
			{
				name: 'Основы торговли на акциях и индексах',
				pages: [
					{
						name: 'Фундаментальный анализ акций',
						texts: [
							'Анализ рынка акций включает в себя, в первую очередь, анализ финансовой деятельности компании. Эта деятельность зависит от того, чем компания занимается. Аналитики понимают, что происходит с компанией, когда она публикует свою отчётность.',
							'В отчётности отражается вся информация по доходам и расходам компании, даётся информация о текущем состоянии дел, а также обнародуется прогноз компании на будущее.',
							'Период такой отчётности компаний может быть разным. В США, например, она публикуется один раз в квартал.',
							'Ключевым показателем, на который смотрят все аналитики, является прибыль на акцию (EPS). Чистая прибыль компании делится на количество её акций. Если EPS растёт, это положительно влияет на цену акции, если EPS падает — наоборот, отрицательно.'
						]
					},
					{
						name: 'Фундаментальный анализ индексов',
						texts: [
							'Прежде всего, анализ индексов заключается в анализе общего состояния экономики. Индекс — это безразмерная величина, которая сообщает о том, что происходит с определённым количеством акций, входящих в этот индекс (базой индекса).',
							'Достаточно часто, когда экономика страны замедляется (наступает рецессия), её ключевые индексы начинают снижаться, так как замедление экономики может плохо влиять на большинство компаний, входящих в индекс.',
							'Анализ индексов во многом схож с анализом рынка валют, когда проводится оценка макроэкономических показателей стран.'
						]
					},
					{
						name: 'Как процентная ставка влияет на индексы?',
						texts: [
							'Рассмотрим, например, процентную ставку Центрального банка. Если она растёт, то валюта страны будет расти, а ключевые индексы, скорее всего, будут падать. Если же ставка снижается, то это приводит к снижению валют. Однако инвесторы считают, что это на руку компаниям, поскольку у них появляется возможность взять более выгодные кредиты, а акции этих компаний начинают расти — что положительно сказывается на индексах.',
						]
					},
				]
			},
			{
				name: 'Основы торговли на ETF',
				pages: [
					{
						name: 'Фундаментальный анализ ETF',
						texts: [
							'Для того чтобы проанализировать ETF, необходимо проанализировать его внутреннюю структуру. Например, рост или падение акций ETF на природный газ напрямую зависит от того, что происходит с природным газом. ETF на индекс ведёт себя так же, как и индекс.',
							'В процессе анализа важно помнить, какой именно ETF вы анализируете. Если reversal, то в случае роста актива (или группы активов), входящих в ETF, сами акции ETF будут падать — и наоборот.',
						]
					},
					{
						name: 'Пример анализа ETF',
						texts: [
							'ETF Nasdaq Reversal 2x — фонд, который является обратным к американскому индексу Nasdaq. Это означает, что если индекс Nasdaq, в силу разных причин, будет снижаться, то акции фонда будут расти. В то же время, рост индекса Nasdaq будет сопровождаться падением акций фонда. 2x означает, что акции фонда будут расти или падать в 2 раза быстрее самого индекса.',
							'Для того чтобы проанализировать, как будут вести себя акции фонда, необходимо, в первую очередь, проанализировать ситуацию на американском фондовом рынке. Если наметились кризисные явления, то есть смысл покупать акции ETF в расчёте на дальнейший рост.',
						]
					},
				]
			},
			{
				name: 'Основы торговли на сырьевых активах',
				pages: [
					{
						name: 'Фундаментальный анализ рынка нефти, газа и меди',
						texts: [
							'Рынок нефти, газа и меди зависит от следующих факторов:',
							'– Количество нефти, газа и меди в мире. Если добыча растёт, а спрос нет, то это приводит к росту остатков сырья. Рост запасов зачастую приводит к снижению цены.',
							'– Добыча нефти, газа и меди в мире. Чем больше добыча — тем ниже цена. В таком случае этих активов становится больше, и спрос может не успевать за количеством добываемого сырья. Например, чтобы оценить добычу нефти в США, можно обратить внимание на количество буровых установок. Эти данные публикуются каждую неделю в пятницу третьей по величине нефтегазовой сервисной компанией Baker Hughes. Чем больше количество буровых установок, тем больше нефти добывается — и соответственно, тем ниже цена.',
							'– Количество нефти, газа и меди в мире. Если добыча растёт, а спрос нет, то это приводит к росту остатков сырья. Рост запасов зачастую приводит к снижению цены.'
						]
					},
					{
						name: 'Фундаментальный анализ рынка драгоценных металлов',
						texts: [
							'Фундаментальные факторы, которые влияют на рынок драгоценных металлов практически такие же, что и факторы, влияющие на цены энергоносителей: спрос, количество добываемого металла и мировые запасы.',
							'Главным показателем, который влияет на цену, остается спрос, поскольку при отсутствии спроса добывать драгоценный металл будут всё меньше, а запасы при этом будут расти, что неизменно приведет к падению цены.',
						]
					},
					{
						name: 'Фундаментальный анализ золота',
						texts: [
							'Особняком от остальных драгоценных металлов стоит золото. Помимо того, что золото используют в производстве (например, ювелирных украшений), оно часто выступает в качестве так называемого защитного актива.',
							'В случае возникновения кризисных ситуаций в экономике, спрос на золото начинает расти, поскольку инвесторы воспринимают данный металл как способ сохранить и защитить свои деньги. Поэтому, в случае публикации негативных макроэкономических данных, цена на золото может вырасти.',
						]
					},
				]
			},
		]
	},
	{
		category_url: 'fundamental-analysis-for-advanced',
		category_name: 'Фундаментальный анализ для продвинутых',
		category_desc: 'Фундаментальный анализ изучает новостной фон как причину изменения цен на макроэкономическом уровне. Он представляет собой анализ экономического и политического состояния стран или отдельных отраслей экономики.',
		subcategories: [
			{
				name: 'Виды событий и новостей',
				pages: [
					{
						name: 'Новости случайные и неожиданные',
						texts: [
							'К неожиданным и случайным обычно относят новости, связанные с политикой или окружающей средой (реже с экономикой). Например, новость о политической нестабильности в Соединённых Штатах способна привести к падению доллара США. Особенно сильное воздействие на финансовые рынки оказывают войны. Стихийные бедствия и техногенные катастрофы большого масштаба могут нанести существенный урон экономике отдельной страны и, как следствие, повлиять на курс её национальной валюты.',
						]
					},
					{
						name: 'Новости планируемые и ожидаемые',
						texts: [
							'К ожидаемым и планируемым новостям обычно относят новости экономического, реже политического характера. Экономические новости — это публикации прогнозов и фактических значений ключевых макроэкономических показателей национальной экономики. Они оказывают прямое воздействие на участников финансовых рынков и уровень котировок',
						]
					},
					{
						name: 'Новостной импульс',
						texts: [
							'Импульс можно разделить на несколько этапов:',
							'– Первоначальный импульс. Резкое, волатильное движение, вызванное выходом новости. Зачастую начинается в первые секунды после оглашения данных.',
							'– Коррекция. После большого импульсного движения часто следует коррекция, которая может достигать трети или половины импульсного движения. Обычно она менее волатильна, чем импульс.',
							'– Флэт (консолидация). После коррекционного движения рынок обычно останавливается и начинается торговля во флэте. Зачастую это финальная стадия новостной формации, после которой влияние новости заканчивается. Флэтовая стадия является важнейшей фазой в поиске рынком «справедливой цены» актива после выхода важной новости.'
						]
					},
					{
						name: 'Новостной тренд',
						texts: [
							'Новостной тренд интересен тем, что он может быть очень мощным — с минимальными коррекциями или вообще без них. Локальный тренд обычно длится на рынке несколько часов. Не рекомендуется торговать против движения цены.',
							'Однако тренды, вызванные фундаментальными факторами, могут быть не только локальными, но и глобальными — с длительностью в месяц или квартал. Основой таких трендов является множество макроэкономической статистики, которая, как пазл, складывается из небольших элементов в общую картину. Если эта картина положительная, то валюта укрепляется. Если наоборот — снижается.',
						]
					},
					{
						name: 'Реакция рынка на новости',
						texts: [
							'Выход новостей может вызвать две реакции:',
							'Новостной импульс. Рынок получает новую информацию резко или неожиданно. В большинстве случаев это сильное несовпадение консенсус-прогнозов и фактических значений опубликованных экономических данных.',
							'Новостной тренд. Новая информация оказывает более плавное и устойчивое влияние на изменение котировок. В основном такие тренды появляются после новых «вводных данных» от политиков во время выступлений/пресс-конференций. В отдельных случаях важные экономические данные тоже вызывают тренды после завершения импульсной формации.'
						]
					},
				]
			},
			{
				name: 'Стратегия торговли на новостях "Профит-прогноз"',
				pages: [
					{
						name: 'Суть стратегии',
						texts: [
							'Суть стратегии «Профит-прогноз» заключается в определении наиболее вероятного направления движения валюты, по которой выходит новость.',
							'Не существует абсолютно точных алгоритмов фундаментального анализа, которые могут спрогнозировать движение цены. Но, анализируя значения консенсус-прогноза и исторические данные, можно с достаточной точностью определить направление движения валюты.',
						]
					},
					{
						name: 'Анализ консенсус-прогноза',
						texts: [
							'Консенсус-прогноз — это средневзвешенный прогноз от ведущих аналитиков, величина, на которую ориентируется рынок при анализе новости. Эти данные записывают в прогнозируемые значения в экономическом календаре.',
							'Предыдущее значение в экономическом календаре — это данные, которые вышли по рассматриваемой новости в прошлый отчётный период.',
						]
					},
					{
						name: 'Определение тенденции',
						texts: [
							'– Если консенсус-прогноз больше предыдущего значения, значит, для этого имеются положительные макроэкономические факторы и, следовательно, предпосылки для укрепления валюты.',
							'– Если консенсус-прогноз меньше предыдущего значения, то наоборот — имеются предпосылки для ослабления валюты.',
							'После выхода новости и фактического значения в экономическом календаре необходимо сравнить это значение со значением консенсус-прогноза. Чем больше будет разница, тем более волатильно отреагирует рынок.'
						]
					},
				]
			},
			{
				name: 'Торговля на акциях',
				pages: [
					{
						name: 'Основы торговли на акциях',
						texts: [
							'Фундаментальный анализ рынка акций включает, в первую очередь, анализ финансовой деятельности компании, которая зависит от того, чем компания занимается. Финансовые показатели компании отражаются в отчётности, куда в том числе входит вся информация по расходам и доходам компании, информация о текущим состоянии дел и прогноз компании на будущее. Период отчётности может быть различным, например, в США такая отчётность публикуется один раз в квартал.',
						]
					},
					{
						name: 'Основные показатели для выбора компании',
						texts: [
							'– P/E — отношение стоимости акции на рынке к чистой прибыли компании, которая приходится на одну акцию. Этот коэффициент показывает, сколько для инвестора будет стоить каждый доллар прибыли. Чем он ниже, тем выгоднее инвестору покупать акции компании.',
							'– PEG – этот показатель характеризует рост коэффициента P/E. Рассчитать его можно разделив P/E на планируемый рост прибыли в будущем году. Если он находится около значения 1,0, но не достигает его, это значит, что акции компании недооценены и покупать их очень выгодно.',
							'– P/S – отношение стоимости одной акции к объёму продаж в году. Чем меньше этот показатель, тем выгоднее для инвестора покупать акции компании.',
							'– ROE – процент доходности акций.',
							'– ROA – отношение прибыли компании к её активам, то есть рентабельность.',
						]
					},
				]
			},
			{
				name: 'Торговля на сырьвых активах',
				pages: [
					{
						name: 'Основы торговли на сырьевых активах',
						texts: [
							'Возьмём за пример рынок нефти. Фундаментальный анализ этого рынка складывается из целой группы ключевых факторов, которые можно отнести к разным группам и категориям. Каждая из них очень важна, поэтому трейдеры, которые торгуют на сырьевых активах, должны постоянно отслеживать их изменения.',
							'Среди наиболее важных факторов можно выделить следующие: геополитика, спрос, статистика по запасам сырой нефти от EIA, количество буровых вышек от Baker & Hughes, отчеты и мероприятия ОПЕК.',
						]
					},
					{
						name: 'Геополитические факторы',
						texts: [
							'Из-за компактного расположения крупнейших месторождений нефти одним из самых важных регионов считается Ближний Восток. Любая напряжённость в этом регионе может серьёзно повлиять на нефтяные котировки.',
						]
					},
					{
						name: 'Спрос',
						texts: [
							'Пожалуй, наиболее значимая категория, способная влиять на котировки нефти как краткосрочно, так и долгосрочно. К сожалению, данные по спросу, в отличие от предложения, обновляются и публикуются не так часто — отчёты Международного энергетического агентства (МАЭ) по нефти выходят ежемесячно.',
						]
					},
					{
						name: 'Статистика по запасам сырой нефти от EIA',
						texts: [
							'Каждую среду после 14:00 (GMT), рынок замирает в ожидании выхода статистики по коммерческим запасам сырой нефти от EIA. Рост запасов (по отношению к прогнозным данным) означает, что или было добыто слишком много нефти, или потребление оказалось более низким. Но, при прочих равных, это чаще приводит к снижению цен на нефть.',
							'И наоборот, снижение запасов означает повышенное потребление или снижение добычи, что является бычьим фактором. Также рынки следят за альтернативной статистикой по запасам от API, которая обычно выходит накануне, во вторник.',
						]
					},
					{
						name: 'Количество буровых вышек от Baker & Hughes',
						texts: [
							'Статистика по количеству буровых вышек играет значительную роль в ценообразовании на рынке.',
							'Сама публикация статистики в моменте слабо влияет на цены, но тренды и тенденции в этих данных достаточно важны.',
							'Считается, что рост вышек рано или поздно приведёт к росту добычи, в то время как их снижение — к падению. Данные публикуются вечером каждой пятницы в экономическом календаре.'
						]
					},
					{
						name: 'Отчёты и мероприятия ОПЕК',
						texts: [
							'ОПЕК — это организация стран-экспортёров нефти, координирующая политику добычи чёрного золота и порядок образования цен на сырьё в мире. Интерес для нефтетрейдеров представляет главным образом ежемесячный отчёт организации по рынку нефти.',
							'Помимо этого, страны, входящие в нефтяной картель, собираются два раза в год, чтобы вынести решение по квотам.',
						]
					},
				]
			},
			{
				name: 'Торговля на криптовалютах',
				pages: [
					{
						name: 'Основы торговли на криптовалютах',
						texts: [
							'Криптовалютный рынок подчиняется основным рыночным правилам. Однако он обладает рядом особенностей, поэтому новостной фон для криптовалют отличается от других рынков. Например, криптовалютные новости выходят без чёткой привязки к какому-либо графику/экономическому календарю. Для того чтобы быть в курсе происходящего в индустрии и получать информацию максимально быстро, необходимо читать специализированные ресурсы, желательно на английском языке.',
						]
					},
					{
						name: 'Новости по биткоину',
						texts: [
							'Прежде всего, обращайте внимание на новости по биткоину. Это первая и главная криптовалюта, которая оказывает существенное влияние на остальные монеты — альткоины. Между ними большую часть времени наблюдается сильная положительная корреляция. Это значит, если биткоин растёт, то будет расти и весь остальной крипторынок, — и наоборот.',
						]
					},
					{
						name: 'Мировые новости',
						texts: [
							'Следите за международными новостями. Сильное влияние на биткоин и в целом на криптовалютный рынок оказывают новости или даже слухи из Китая. Одним из главных фундаментальных вопросов является регуляция и законодательные изменения в странах Юго-Восточной Азии, а также Северной Америки.',
							'Общее снижение доверия к мировой финансовой системе и национальным валютам приводит к спросу на криптовалюты, например, такое уже наблюдалось в таких странах как Венесуэла и Зимбабве с 2016-2017 года. Люди больше доверяют биткоину как средству сохранения стоимости, чем местным валютам.',
						]
					},
					{
						name: 'Блокчейн и криптобиржи',
						texts: [
							'Следите за новостями о криптовалютных биржах. Взломы или атаки на них, приостановка их работы часто вызывают локальную панику и снижение котировок.',
							'Будьте в курсе новостей об обновлениях блокчейнов криптовалют. Форки, халвинг, смена протокола консенсуса, альфа-тестирования, обновления, ввод нового функционала и прочие новости обычно усиливают покупательские настроения.',
						]
					},
					{
						name: '«Миграция китов»',
						texts: [
							'Из-за специфики криптовалютного рынка всем желающим видны движения средств в блокчейне. Поэтому ещё одна разновидность фундаментального анализа — слежка за перемещениями криптоактивов крупных игроков, так называемых китов. Также полезно наблюдать за новой эмиссией стейблкоинов (таких как USDT), после которой нередко начинается рост.',
						]
					},
				]
			},
		]
	},
	{
		category_url: 'chart',
		category_name: 'График',
		category_desc: '',
		subcategories: [
			{
				name: 'Зонный график',
				pages: [
					{
						name: 'Что такое линейный график',
						texts: [
							'Линейный график — это способ отображения изменений цены актива (котировок).',
							'График состоит из точек, которые отмечаются через определенные промежутки времени и соединяются.',
							'Визуально он представляет собой непрерывную ломаную линию, за что получил такое название.',
							'Область под линией закрашена цветом, поэтому график еще называют зонным.',
						]
					},
				]
			},
			{
				name: 'Японские свечи',
				pages: [
					{
						name: 'Что такое свечной график',
						image: '/images/education/42.png',
						texts: [
							'Свечной график — это способ отображения изменений цены актива.',
							'Он состоит из похожих на свечи цветных прямоугольников и вертикальных линий, за что получил такое название.',
							'Каждый цветной прямоугольник — тело свечи.',
							'<img src="/images/education/43.png" alt=""/>',
							'Линии над ним и под ним называют тенями.',
							'<img src="/images/education/44.png" alt=""/>',
							'Вид графика был придуман в Японии, поэтому чаще всего его называют «Японские свечи».'
						]
					},
					{
						name: 'Зеленая свеча',
						texts: [
							'Зеленая свеча указывает на то, что цена актива росла.',
							'<img src="/images/education/45.png" alt=""/>',
							'Нижняя граница тела — цена актива на момент открытия свечи.',
							'<img src="/images/education/46.png" alt=""/>',
							'Верхняя граница — цена актива на момент закрытия свечи.',
							'<img src="/images/education/47.png" alt=""/>',
							'Тень под телом свечи показывает минимум, которого достигала цена актива за определенный период времени. Тень над телом — максимум.',
							'<img src="/images/education/48.png" alt=""/>',
							'Иногда можно встретить черно-белую цветопередачу свечей. На таком графике зеленая свеча будет соответствовать белой.'
						]
					},
					{
						name: 'Красная свеча',
						texts: [
							'Красная свеча указывает на то, что цена актива падала.',
							'<img src="/images/education/49.png" alt=""/>',
							'Верхняя граница тела обозначает цену актива на момент открытия свечи.',
							'<img src="/images/education/50.png" alt=""/>',
							'Нижняя граница — цену актива на момент закрытия свечи.',
							'<img src="/images/education/51.png" alt=""/>',
							'Тень под телом свечи показывает минимум, которого достигала цена актива за определенный период времени. Тень над телом — максимум.',
							'<img src="/images/education/52.png" alt=""/>',
							'Иногда можно встретить черно-белую цветопередачу свечей. На таком графике красная свеча будет соответствовать черной.'
						]
					},
					{
						name: 'Таймфрейм свечи',
						texts: [
							'Таймфрейм — время, за которое формируется одна свеча.',
							'Например, если выбрать таймфрейм в полчаса, каждая свеча будет соответствовать 30 минутам и покажет, как изменилась цена актива за это время.',
							'<img src="/images/education/53.png" alt=""/>',
							'Таймфрейм можно изменить рядом с графиком.',
							'<img src="/images/education/54.png" alt=""/>',
						]
					},
				]
			},
			{
				name: 'Хайкен Аши',
				pages: [
					{
						name: 'Что такое Хайкен Аши',
						texts: [
							'Хайкен Аши помогает определить направление движения цены актива и найти точки разворота тренда.',
							'Он представляет собой усредненные японские свечи, поэтому его часто ошибочно называют графиком.',
							'<img src="/images/education/55.png" alt=""/>',
							'На самом деле Хайкен Аши — индикатор. Он трансформирует свечной график, сглаживает колебания цены и упрощает анализ трендов.',
							'Индикатор помогает торговать и по направлению движения цены, и в моменты разворота тренда.'
						]
					},
					{
						name: 'Торговля по тренду',
						texts: [
							'Зеленая свеча без нижней тени указывает на сильное восходящее движение. Вероятнее всего, цена актива продолжит расти.',
							'<img src="/images/education/56.png" alt=""/>',
							'Красная свеча без верхней тени указывает на сильное нисходящее движение. Вероятнее всего, цена актива продолжит падать.',
							'<img src="/images/education/57.png" alt=""/>',
						]
					},
					{
						name: 'Торговля на развороте тренда. Рост цены',
						texts: [
							'Если на нем появилась красная свеча с верхней тенью, возможен разворот тренда и рост цены актива.',
							'<img src="/images/education/58.png" alt=""/>',
							'Наиболее сильный сигнал на повышение — появление на графике красной свечи с маленьким телом и двумя длинными тенями.',
							'Если после нее сформировалась зеленая свеча с нижней тенью, это дополнительный сигнал, который подтверждает разворот тренда.'
						]
					},
					{
						name: 'Торговля на развороте тренда. Падение цены',
						texts: [
							'Если на графике появилась зеленая свеча с нижней тенью, тренд может развернуться, и цена актива начнет падать.',
							'<img src="/images/education/59.png" alt=""/>',
							'Наиболее сильный сигнал на понижение — появление на графике зеленой свечи с маленьким телом и двумя длинными тенями.',
							'Если после нее сформировалась красная свеча с верхней тенью, это дополнительный сигнал, который подтверждает разворот цены.'
						]
					},
					{
						name: 'Рекомендации по использованию Хайкен Аши',
						texts: [
							'Рекомендуем сочетать Хайкен Аши с другими индикаторами.',
							'Для торговли по направлению движения цены комбинируй его с трендовыми индикаторами, а для сделок на развороте тренда — с осцилляторами.',
						]
					},
				]
			},
			{
				name: 'Бары',
				pages: [
					{
						name: 'Что такое бары',
						texts: [
							'Бары — один из способов отображения изменений цены актива.',
							'Бары схожи со свечным графиком и фактически содержат ту же информацию, что и японские свечи.',
							'Они отличаются только внешним видом. Визуально бар представляет собой вертикальную черту с двумя горизонтальными черточками.',
							'<img src="/images/education/60.png" alt=""/>',
						]
					},
					{
						name: 'Строение баров',
						texts: [
							'Горизонтальная черточка слева показывает цену открытия, черточка справа — цену закрытия.',
							'<img src="/images/education/61.png" alt=""/>',
							'Центральная вертикальная линия демонстрирует максимум и минимум, которых цена достигала за определенное время — таймфрейм.',
							'<img src="/images/education/62.png" alt=""/>',
						]
					},
					{
						name: 'Таймфрейм баров',
						texts: [
							'Один бар равен таймфрейму.',
							'Если выбрать таймфрейм в 1 час, каждый бар будет соответствовать 60 минутам и покажет, как изменилась цена актива за это время.',
							'<img src="/images/education/63.png" alt=""/>',
							'Таймфрейм можно изменить рядом с графиком.'
						]
					},
					{
						name: 'Как читать сигналы баров',
						texts: [
							'Зеленые бары указывают, что цена актива росла. Красные — что падала.',
							'Наведи стрелку мыши на бар. Ты увидишь котировки открытия/закрытия, а также минимальную и максимальную котировки, которых достигал актив в выбранный временной период.',
							'<img src="/images/education/64.png" alt=""/>',
						]
					},
				]
			},
		]
	},


	{
		category_url: 'example_link',
		category_name: 'Название раздела',
		category_desc: 'Описание раздела',
		subcategories: [
			{
				name: 'Название части раздела',
				pages: [
					{
						name: 'название страницы',
						image: '/images/education/.png',
						texts: [
							'',
							'',
						]
					},
				]
			},
		]
	},

]