import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import Deals from './deals'

import {setDeal} from '../../../actions/deals'

import CurrentChartInfo from '../terminalHeader/currentChartInfo'

import './index.scss'
import {Link} from "react-router-dom";

class TerminalControl extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            tradeTime: 1,
            tradePrice: 1
        };

    }

    tradeTimeCounter(value) {
        if (!value) {
            if (value !== 0) this.setState({tradeTime: ''});
            return false;
        }

        let newValue = +(typeof value === "string" ? value.replace(/\D+\^0+/g, "") : value);

        newValue = newValue <= 1 ? 1 : newValue;
        newValue = newValue > 60 ? 60 : newValue;

        this.setState({tradeTime: newValue});
    }

    tradePriceCounter(value) {
        if (!value) {
            if (value !== 0) this.setState({tradePrice: ''});
            return false;
        }

        let newValue = +(typeof value === "string" ? value.replace(/\D+\^0+/g, "") : value);

        newValue = newValue <= 1 ? 1 : newValue;
        newValue = newValue > 1000000 ? 1000000 : newValue;

        this.setState({tradePrice: newValue});
    }

    setDeal(type) {
        const {
            tradeTime,
            tradePrice
        } = this.state;

        //const { strike } = this.props.terminal;

        this.props.setDeal({
            type: type,
            minutes: tradeTime,
            amount: tradePrice,
            wallet: localStorage.wallet,
            chart_id: +localStorage.chartId,
        })
    }


    render() {
        let {
            tradeTime,
            tradePrice
        } = this.state;

        let {
            current_chart,
            allowGetBonus
        } = this.props.terminal;

        /*let priceYield = +tradePrice + (+tradePrice * (current_chart.yield / 100));
        priceYield = priceYield.toFixed(2);*/

        let yieldProcent = current_chart.yield;

        return (



            <div className="terminal-control">

                <div className="prof_mobile__bonus">
                    {allowGetBonus ?
                        <Link to="/cabinet/increase" className="btn btn_blue mob_bonus_btn">
                            <div className="bonus_btn__icon">
                                <img src={`/images/icons/boost.svg`} alt="бонус"/>
                            </div>
                            <div className="bonus_btn__title">Получите бонус 100% на Ваш депозит</div>
                            {/*<div className="bonus_btn__value">100%</div>*/}
                        </Link>
                        : false}
                </div>

                <div className="form-box">
                    <div className="form_box__title">
                        <CurrentChartInfo data={current_chart} switchPreloader={true}/>
                    </div>
                    <div className="input_wrapper">
                        <div className="form_box__tradeTime">
                            <div className="tradeTime__title">Время <span>(мин)</span></div>
                            <div className="tradeTime__inputBox">
                                <button className="minus" onClick={() => this.tradeTimeCounter(tradeTime - 1)}>-</button>
                                <input type="text" id="tradeTimeInput" value={tradeTime}
                                       onBlur={() => !tradeTime ? this.setState({tradeTime: 1}) : false}
                                       onChange={e => this.tradeTimeCounter(e.target.value)}/>
                                <label htmlFor="tradeTimeInput">мин</label>
                                <button className="plus" onClick={() => this.tradeTimeCounter(tradeTime + 1)}>+</button>
                            </div>
                        </div>

                        <div className="form_box__tradePrice">
                            <div className="tradePrice__title">Инвестиция <span>($)</span></div>
                            <div className="tradePrice__inputBox">
                                <button className="minus" onClick={() => this.tradePriceCounter(tradePrice - 1)}>-</button>
                                <input type="text" id="tradePriceInput"
                                       value={tradePrice}
                                       onBlur={() => !tradePrice ? this.setState({tradePrice: 1}) : false}
                                       onChange={e => this.tradePriceCounter(e.target.value)}
                                />
                                <label htmlFor="tradePriceInput">$
                                </label>
                                <button className="plus" onClick={() => this.tradePriceCounter(tradePrice + 1)}>+</button>
                            </div>
                        </div>
                    </div>
                    <div className="form_box__strike">
                        <button className="strike_btn_green" onClick={() => this.setDeal('up')}>
                                    <span className="strike_btn__prop">
                                        <span>Выше</span>
                                        <span className="btn_icon">
                                            <img src="images/icons/arrow_up_ctrl.svg" alt="Выше"/>
                                        </span>
                                    </span>
                            <span className="strike_btn__value">
                                        +{yieldProcent}%
                                    </span>
                        </button>
                        <div className="strike_desc">
                            <div className="desc__prop">Ваша выплата:</div>
                            <div className="desc__value">{(tradePrice * (1 + yieldProcent  / 100)).toFixed(2)} $</div>
                        </div>
                        <button className="strike_btn_red" onClick={() => this.setDeal('down')}>
                                     <span className="strike_btn__prop">
                                        <span>Ниже</span>
                                        <span className="btn_icon">
                                            <img src="images/icons/arrow_up_ctrl.svg" alt="Ниже"/>
                                        </span>
                                    </span>
                            <span className="strike_btn__value">
                                       +{yieldProcent}%
                                    </span>
                        </button>
                    </div>
                </div>
                <Deals/>

            </div>


        )
    }

}

TerminalControl.propTypes = {
    setDeal: PropTypes.func.isRequired,
    terminal: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
    terminal: state.terminal
})

const mapDispatchToProps = (dispatch) => ({
    setDeal: (data) => dispatch(setDeal(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(TerminalControl);