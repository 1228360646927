import React from 'react'

import Preload from '../../elements/preload'

import './index.scss'

class CurrentChartInfo extends React.Component {

	getIcon() {
		let { symbol } = this.props.data;
		
		let path = '/images/terminal/icons/';

		return (
			<div className="icons">
				<img src={`${path + symbol}.png`} alt=""/>
			</div>
		)
	}

	render() {
		if(!this.props.data.name) return false;

		const {
			data,
			switchPreloader
		} = this.props;

		if(switchPreloader && data.id !== +localStorage.chartId) {
			return (
				<div className="current-chart-info">
					<Preload color="#fff"/>
				</div>
			)
		}

		return (
			<div className="current-chart-info item-left-col">
				<div className="chart-info_left tool">
					{this.getIcon()}
					<div className="tool__value title">{data.name}</div>
				</div>
				<div className="yield">
					<div className="yield__prop">Выплата:</div>
					<div className="yield__value">
						{data.yield}%
					</div>
				</div>
			</div>
		)
	}

}

export default CurrentChartInfo;