import React from 'react'

class WhyUs extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
		};

	}

	render() {

		return (
			<section className="why-us">
				{/*<div className="title">Почему мы?</div>*/}
				{/*<div className="desc"></div>*/}

				<div className="container">
					<div className="advantages__wrapper">
						<ul className="advantages__items">
							<li className="advantages__item">
								<div className="item_left">
									<img src="/images/icons/icon_indicators.png" alt="Торговые Индикаторы"/>
								</div>
								<div className="item_right">
									<div className="steps__item-title">Торговые Индикаторы</div>
									<div className="steps__item-desc">
										Работайте с лучшими инструментами, анализируя рынок эффективнее
									</div>
								</div>
							</li>
							<li className="advantages__item">
								<div className="item_left">
									<img src="/images/icons/icon_profit.png" alt="Высокая доходность"/>
								</div>
								<div className="item_right">
									<div className="steps__item-title">Высокая доходность</div>
									<div className="steps__item-desc">
										Доходность по некоторым активам достигает 90%
									</div>
								</div>
							</li>
							<li className="advantages__item">
								<div className="item_left">
									<img src="/images/icons/icon_safety.png" alt="Безопасность капитала"/>
								</div>
								<div className="item_right">
									<div className="steps__item-title">Безопасность капитала</div>
									<div className="steps__item-desc">
										Информация о транзакциях и надежная защита средств
									</div>
								</div>
							</li>
							<li className="advantages__item">
								<div className="item_left">
									<img src="/images/icons/icon_min.png" alt="Мин. пополнение 50$"/>
								</div>
								<div className="item_right">
									<div className="steps__item-title">Мин. пополнение 50$</div>
									<div className="steps__item-desc">
										Комфортный старт без крупных вложений уже сейчас
									</div>
								</div>
							</li>
							<li className="advantages__item">
								<div className="item_left">
									<img src="/images/icons/icon_out.png" alt="Вывод Средств"/>
								</div>
								<div className="item_right">
									<div className="steps__item-title">Вывод Средств</div>
									<div className="steps__item-desc">
										Оперативный вывод средств в течении суток без комиссии
									</div>
								</div>
							</li>
							<li className="advantages__item">
								<div className="item_left">
									<img src="/images/icons/icon_tech.png" alt="Технологичная платформа"/>
								</div>
								<div className="item_right">
									<div className="steps__item-title">Технологичная платформа</div>
									<div className="steps__item-desc">
										Наша платформа работает на самых современных технологиях
									</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</section>
		)
	}

}

export default WhyUs;